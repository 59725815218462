import React, { useState, useRef } from "react";
import "./forgotpasswordSteps.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios"; // Make sure axios is imported
import {
  AuthServices,
  forgotEmailOtpVerification,
  forgotOtpVerification,
} from "../services/AuthServices";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const ForgotpasswordSteps = ({ setSignup }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState("1");
  const [sentOtp, setSentOtp] = useState(null);
  const otpInputs = useRef([]);

  const initialValues = {
    email: "",
    mob_number: "",
    email_otp: ["", "", "", "", "", ""],
    phone_otp: "",
    password: "",
    confirm_password: "",
  };

  let errorValidation;
  if (step === "1") {
    errorValidation = yup.object({
      email: yup.string().email().required("Email is required"),
    });
  } else if (step === "2") {
    errorValidation = yup.object({
      email_otp: yup
        .array()
        .of(
          yup
            .string()
            .matches(/^\d{1}$/, "Each OTP digit must be exactly 1 digit")
        )
        .length(6, "Email OTP must be exactly 6 digits")
        .required("Email OTP is required"),
    });
  } else if (step === "3") {
    errorValidation = yup.object({
      password: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Z])(?=.*[/@#])[A-Za-z\d/@#]{8,}$/,
          "Password must start with a capital letter and include /, @, or #"
        ),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    });
  }

  const focusInput = (index, direction) => {
    if (direction === "next" && otpInputs.current[index + 1]) {
      otpInputs.current[index + 1].focus();
    } else if (direction === "prev" && otpInputs.current[index - 1]) {
      otpInputs.current[index - 1].focus();
    }
  };

  const handleSendMobileCode = async (mob_number) => {
    try {
      await AuthServices.sendMobileCode(mob_number);
      setStep("2");
    } catch (error) {
      console.error(
        "Error sending mobile verification code:",
        error.response || error.message
      );
      window.alert("Failed to send mobile verification code.");
    }
  };

  const handleVerifyOtps = async (values) => {
    try {
      const emailOtp = values.email_otp.join("");

      await AuthServices.verifyOtps(emailOtp, values.phone_otp);
      setStep("4");
    } catch (error) {
      console.error("Error verifying OTPs:", error.response || error.message);
      window.alert("Failed to verify OTPs.");
    }
  };

  const handleChangePassword = async (values) => {
    try {
      const response = await AuthServices.changePassword(
        values.email,
        sentOtp,
        values.password,
        "email"
      );
      window.alert("Password changed successfully.");
      navigate("/ ");
    } catch (error) {
      window.alert("Failed to change password.");
    }
  };

  const handleSendEmailOtp = async (values) => {
    try {
      const response = await forgotEmailOtpVerification(values.email);
      window.alert(`OTP sent to ${values.email}`);

      setSentOtp(response.data.emailOtp);
      setStep("2");
    } catch (error) {
      window.alert(error);
    }
  };

  const handleVerifyEmail = async (values) => {
    const otp = values.email_otp.toString().replace(/,/g, "");
    if (otp === sentOtp.toString()) {
      window.alert("Email verified successfully");
      setStep("3");
    } else {
      window.alert("Incorrect OTP");
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    switch (step) {
      case "1":
        handleSendEmailOtp(values);
        break;
      case "2":
        handleVerifyEmail(values);
        break;
      case "3":
        handleChangePassword(values);
        break;
      default:
        break;
    }
    setSubmitting(false);
  };
  const handleVerifyNumber = async (e, email, setFieldValue) => {
    setFieldValue("email", email);
    if (!email) {
      window.alert("Enter Email Id");
      return;
    } else {
      try {
        const response = await forgotEmailOtpVerification(email);
        if (response.status === 200) {
          setStep("2");
        } else {
          // const anotherResponse = await anotherApiCall(email);
          // Handle the alternative API response as needed
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          window.alert("Enter the valid OTP");
        } else {
          // const anotherResponse = await anotherApiCall(email);
          // Handle the alternative API response as needed
        }
      }
    }
  };

  const handleDeleteDigit = (e, index, setFieldValue) => {
    if (e.key === "Backspace" && index > 0 && !otpInputs.current[index].value) {
      focusInput(index, "prev");
    } else if (
      e.key === "Delete" &&
      index < 5 &&
      !otpInputs.current[index].value
    ) {
      focusInput(index, "next");
    }
  };

  // const anotherApiCall = async (email) => {
  //   // Define the alternative API call here

  //   return await axios.post(`${REACT_APP_API_URL}/another-endpoint`, { email });
  // };

  return (
    <div className="background-img">
      <div className="forgetpassword-main">
        <Formik
          initialValues={initialValues}
          validationSchema={errorValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form style={{ height: "100%" }}>
              {step === "1" && (
                <div
                  className="form-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <h2>Forgot Your Password</h2>
                    <span>Don't worry! We've got your back</span>
                    <div className="label" style={{ marginTop: "20px" }}>
                      <span style={{ fontWeight: "bold" }}>Email ID</span>
                      <Field
                        name="email"
                        type="email"
                        className="input"
                        style={{ width: "100%" }}
                        placeholder="Enter your Email ID"
                      />
                      {touched.email && errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <button type="submit" className="submit-button">
                        SEND VERIFICATION CODE
                      </button>
                    </div>
                  </div>

                  <div style={{ width: "458px", gap: "4px" }}>
                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "rgba(121, 121, 121, 1)",
                        }}
                      >
                        Don't have an account?{" "}
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "rgba(1, 124, 87, 1)",
                          }}
                        >
                          Sign up here.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {step === "2" && (
                <div className="form-container">
                  <h2>Verify Your Email Address</h2>
                  <span
                    style={{
                      display: "flex",
                      color: "rgba(121, 121, 121, 1)",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginTop: "-10px",
                    }}
                  >
                    We've sent you a one-time password on your email
                  </span>
                  <hr
                    style={{
                      width: "458px",
                      height: "1px",
                      border: "1px solid rgba(228, 228, 228, 1)",
                      marginTop: "30px",
                    }}
                  />
                  <div
                    className="otp-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "8px",
                      marginTop: "20px",
                    }}
                  >
                    {values.email_otp.map((_, index) => (
                      <Field
                        key={index}
                        name={`email_otp[${index}]`}
                        type="text"
                        className="otp-input"
                        maxLength="1"
                        innerRef={(el) => (otpInputs.current[index] = el)}
                        onKeyDown={(e) =>
                          handleDeleteDigit(e, index, setFieldValue)
                        }
                        onChange={(e) => {
                          setFieldValue(`email_otp[${index}]`, e.target.value);

                          if (e.target.value.length === 1) {
                            focusInput(index, "next");
                          }
                        }}
                      />
                    ))}
                    {touched.email_otp && errors.email_otp && (
                      <span className="error">{errors.email_otp}</span>
                    )}
                  </div>

                  <button type="submit" className="submit-button">
                    VERIFY EMAIL
                  </button>
                  <div
                    style={{ width: "458px", gap: "4px", marginTop: "50px" }}
                  >
                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "rgba(121, 121, 121, 1)",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        Don't have an account?{" "}
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "rgba(1, 124, 87, 1)",
                          }}
                        >
                          Sign up here.
                        </span>
                      </span>
                    </div>
                  </div>

                  {step === "2" && (
                    <div
                      className="form-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100vh",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "458px",
                          gap: "10px",
                          marginTop: "190px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "458px",
                          height: "227px",
                          gap: "4px",
                          display: "flex",
                        }}
                      >
                        <div
                          className="form-container"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100vh", // Full viewport height to push content down
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "458px",
                              gap: "10px",
                              marginTop: "190px",
                            }}
                          ></div>

                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "rgba(121, 121, 121, 1)",
                              }}
                            >
                              {/* Don't have an account?{" "} */}
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "rgba(1, 124, 87, 1)",
                                }}
                              >
                                Sign up here.
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "rgba(121, 121, 121, 1)",
                          }}
                        >
                          Didn't get the code?{" "}
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "rgba(1, 124, 87, 1)",
                            }}
                          >
                            Resend Code
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {step === "3" && (
                <div className="form-container">
                  <h2>Change Password</h2>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "rgba(121, 121, 121, 1)",
                    }}
                  >
                    Welcome to settlemed
                  </span>
                  <hr
                    style={{
                      width: "458px",
                      height: "1px",
                      border: "1px solid rgba(228, 228, 228, 1)",
                    }}
                  />
                  <div className="label">
                    <span style={{ fontWeight: "bold" }}>Enter Password</span>
                    <Field
                      name="password"
                      type="password"
                      className="input"
                      style={{ width: "100%" }}
                      placeholder="*****"
                    />
                    {touched.password && errors.password && (
                      <span className="error">{errors.password}</span>
                    )}
                  </div>
                  <div className="label">
                    <span style={{ fontWeight: "bold" }}>
                      Re-enter Password
                    </span>
                    <Field
                      name="confirm_password"
                      type="password"
                      className="input"
                      style={{ width: "100%" }}
                      placeholder="*****"
                    />
                    {touched.confirm_password && errors.confirm_password && (
                      <span className="error">{errors.confirm_password}</span>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="submit-button"
                    // onClick={() => handleChangePassword(values)}
                    disabled={isSubmitting}
                  >
                    Save and Proceed
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
