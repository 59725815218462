import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { logOut } from "../../../config/user";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Switch,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import "./SubadminList.scss";
import CustomPagination from "../../../utils/paginationUtils";
import { Navigate, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EditIcon from "../../../assets/editIcon.svg";
import { getlist, updateUser } from "../../../services/AdminServices";

const alternatingRowColor = (index) =>
  index % 2 === 0 ? "#F9F9F9" : "#FFFFFF";

const UserTable = () => {
  const [adminData, setAdminData] = useState([]);
  const [sortAscending, setSortAscending] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [active, setActive] = useState(null); // null = all, true = active, false = inactive
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedData, setDisplayedData] = useState([]);
  const [isInactiveModal, setIsInactiveModal] = useState(false);
  const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleToggleStatus = async (userId, status) => {
    const data = {
      userid: userId,
      status: status === "ACTIVE" ? "DISABLE" : "ACTIVE",
    };
    try {
      await updateUser(data);
      window.location.reload();
    } catch (error) {
      window.alert(error);
    }
  };

  const handleStatusChange = (status, id) => {
    if (status === "DISABLE") {
      handleToggleStatus(id, status);
    } else {
      setIsInactiveModal(true);
    }
  };
  const handleSort = (column) => {
    setSortAscending((prev) => !prev);
    setSortedColumn(column);
  };

  const getFilteredData = () => {
    let filteredData = [...adminData];

    // Filter by status
    if (active !== null) {
      filteredData = filteredData.filter((user) =>
        active ? user.status === "Active" : user.status === "Inactive"
      );
    }

    // Filter by search term
    if (searchTerm) {
      filteredData = filteredData.filter((user) =>
        user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filteredData;
  };

  const getSortedData = () => {
    let sortedData = getFilteredData();

    // Sorting logic
    if (sortedColumn) {
      sortedData = sortedData.sort((a, b) => {
        if (a[sortedColumn] < b[sortedColumn]) return sortAscending ? -1 : 1;
        if (a[sortedColumn] > b[sortedColumn]) return sortAscending ? 1 : -1;
        return 0;
      });
    }

    setDisplayedData(sortedData);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleActiveClick = () => {
    const filteredData = adminData.filter((data) => data.status === "ACTIVE");
    setDisplayedData(filteredData);
  };

  const handleInactiveClick = () => {
    const filteredData = adminData.filter((data) => data.status === "INACTIVE");
    setDisplayedData(filteredData);
  };

  const handleAllClick = () => {
    setDisplayedData(adminData);
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getlist();
      setAdminData(response.data.Users);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setDisplayedData(adminData);
  }, [adminData]);

  useEffect(() => {
    getSortedData();
  }, [adminData, sortAscending, sortedColumn, active, searchTerm]);
  return (
    <div
      style={{ fontFamily: "Inter", padding: 32, marginTop: 93, width: "100%" }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Staff List
      </span>
      <div className="heading">
        Sub Admin List
        <div className="btn-container">
          <button
            className="outlined-btn"
            style={{ width: "88px", backgroundColor: "white" }}
            onClick={handleActiveClick}
          >
            ACTIVE
          </button>
          <button
            className="outlined-btn"
            onClick={handleInactiveClick}
            style={{ backgroundColor: "white" }}
          >
            INACTIVE
          </button>
          <button
            style={{ cursor: "pointer" }}
            className="filled-btn"
            onClick={() => navigate("/addstaff")}
          >
            + ADD STAFF
          </button>
        </div>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "20px",
        }}
      />
      <div>
        <div className="tablecontainer">
          <Paper className="list-paper">
            <div
              style={{
                width: "1184px",
                height: "86px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    marginBottom: "10px",
                    marginLeft: "-170px",
                  }}
                >
                  Manage
                </span>

                <div className="buttonbox" style={{}}>
                  <div
                    style={{
                      display: "flex",
                      width: "702px",
                      height: "34px",
                      gap: "16px",
                      justifyContent: "space-between",
                      marginLeft: "-190px",
                    }}
                  >
                    <text>Sort by</text>
                    <button className="outlined-btn" style={{ width: "194px" }}>
                      Select ALL
                    </button>
                    <select className="outlined-btn" style={{ width: "194px" }}>
                      <option>CASE STATUS</option>
                    </select>
                    <select className="outlined-btn" style={{ width: "210px" }}>
                      <option>DOCUMENT STATUS</option>
                    </select>
                  </div>
                </div>
              </div>

              <InputBase
                type="text"
                className="input-search"
                placeholder="Search anything"
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: 14,
                  paddingLeft: 18,
                  color: "#191919",
                  width: 350,
                  height: 36,
                  border: "1px solid #E4E4E4",
                }}
                startAdornment={<SearchIcon style={{ color: "#797979" }} />}
              />
            </div>
            {isLoading ? (
              <div className="h-[500px] flex justify-center items-center ">
                <CircularProgress />
              </div>
            ) : displayedData && displayedData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th
                      className="tableheading"
                      onClick={() => handleSort("username")}
                    >
                      Username
                      <span className="arrow-icon"></span>
                    </th>
                    <th
                      className="tableheading"
                      onClick={() => handleSort("name")}
                    >
                      Name
                      <span className="arrow-icon"></span>
                    </th>
                    <th
                      className="tableheading"
                      onClick={() => handleSort("email")}
                    >
                      Email
                      <span className="arrow-icon"></span>
                    </th>
                    <th
                      className="tableheading"
                      onClick={() => handleSort("phoneNumber")}
                    >
                      Phone Number
                      <span className="arrow-icon"></span>
                    </th>
                    <th
                      className="tableheading"
                      onClick={() => handleSort("role")}
                    >
                      Role
                      <span className="arrow-icon"></span>
                    </th>
                    {/* <th
                      className="tableheading"
                      onClick={() => handleSort("document")}
                    >
                      Document
                      <span className="arrow-icon"></span>
                    </th> */}
                    <th
                      className="tableheading"
                      onClick={() => handleSort("status")}
                    >
                      <span className="arrow-icon"></span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {displayedData?.map((user, index) => (
                    <tr
                      key={user.id}
                      style={{ backgroundColor: alternatingRowColor(index) }}
                    >
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td className="tablecontent" style={{ width: "25%" }}>
                        {user.userName}
                      </td>
                      <td className="tablecontent" style={{ width: "25%" }}>
                        {user.fullName}
                      </td>
                      <td className="tablecontent" style={{ width: "25%" }}>
                        {user.email}
                      </td>
                      <td className="tablecontent" style={{ width: "25%" }}>
                        {user.phone}
                      </td>
                      <td className="tablecontent" style={{ width: "25%" }}>
                        {user.position}
                      </td>

                      <td className="tablecontent">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span style={{ textTransform: "capitalize" }}>
                            {user?.status === "ACTIVE" ? "Active" : "Inactive"}
                          </span>
                          <Switch
                            checked={user.status === "ACTIVE"}
                            onChange={() => {
                              handleStatusChange(user.status, user.id);
                              setSelectedSubAdmin(user);
                            }}
                            inputProps={{ "aria-label": "toggle status" }}
                            style={{ color: "#017C57" }}
                          />
                          <span>
                            <img src={EditIcon} alt="edit-icon" />
                          </span>
                        </Stack>
                      </td>
                      {/* <td
                        className="tablecontent"
                        style={{ width: "10px", cursor: "pointer" }}
                      >
                        <DeleteIcon style={{ color: "#017C57" }} />
                      </td>
                      <td className="tablecontent">
                        <BorderColorOutlinedIcon
                          style={{ color: "#017C57", cursor: "pointer" }}
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className=" text-2xl font-bold text-center mt-16">
                No Data Found
              </div>
            )}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ height: "20px", width: "20px" }} />
            </div> */}
          </Paper>
          <div className="list-pagination">
            <CustomPagination />
          </div>
        </div>
      </div>

      {isInactiveModal && (
        <div className="fixed h-screen w-screen top-0 right-0 flex justify-center items-center bg-[#017C5780] z-50">
          <div
            style={{ border: "1px solid #E4E4E4" }}
            className="w-[35%] h-[35%] flex flex-col justify-center items-center rounded-[16px] bg-white px-12"
          >
            <div className="text-2xl text-[#191919] font-semibold">
              Are you sure you want to inactivate this Staff?
            </div>
            <div className="text-[#797979] mt-4">Please confirm to proceed</div>
            <div className="flex justify-center gap-2 mt-16">
              <div
                style={{ border: "1px solid #017C57" }}
                className="w-[152px] text-center text-xs font-semibold text-white bg-[#017C57] py-2 rounded-full cursor-pointer"
                onClick={() => setIsInactiveModal(false)}
              >
                GO BACK
              </div>
              <div
                style={{ border: "1px solid #017C57" }}
                className="w-[152px] text-center text-xs font-semibold text-[#017C57]  py-2 rounded-full cursor-pointer"
                onClick={() => {
                  handleToggleStatus(
                    selectedSubAdmin.id,
                    selectedSubAdmin.status
                  );
                  setIsInactiveModal(false);
                }}
              >
                DEACTIVATE
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTable;
