import { useState, useEffect } from "react";
import CloseIcon from "../assets/crossIcon.svg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NotificationServices } from "../services/NotificationServices";
const EditNotificationForm = ({
  selectedNotification,
  setOpen,
  refreshNotifications,
}) => {
  const initialValues = selectedNotification
    ? selectedNotification
    : {
        id: null,
        notificationType: null,
        event: null,
        from: null,
        to: null,
        title: null,
        body: null,
        updatedBy: null,
        createdBy: null,
        createdAt: null,
        updatedAt: null,
      };

  const handleSubmit = async (values) => {
    const data = [values];
    try {
      const response = await NotificationServices.updateDefaultNotifications(
        data
      );
      window.alert("Notification Updated");
      setOpen(false);
      refreshNotifications();
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-center items-center bg-[#017C5780] z-20">
      <div className="w-[50%] h-[60%] bg-white rounded-[16px] p-4">
        <div className="flex justify-end">
          <img
            src={CloseIcon}
            alt="close-icon"
            className="cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="mt-4 overflow-y-scroll h-[90%]">
          <Formik
            initialValues={initialValues}
            // validationSchema={doctorDetailsSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <div className="w-full grid grid-cols-3 gap-4">
                  <label className="label1">
                    <div className="text-content">
                      Title <span className="required">*</span>
                    </div>
                    <Field
                      type="text"
                      className="input-field"
                      name="title"
                      placeholder="Enter Title"
                    />
                    {touched.title && errors.title && (
                      <div className="field-error">{errors.title}</div>
                    )}
                  </label>
                  <label className="label1">
                    <div className="text-content">
                      From <span className="required">*</span>
                    </div>
                    <Field
                      type="text"
                      className="input-field"
                      name="from"
                      placeholder="Enter From Value"
                    />
                    {touched.from && errors.from && (
                      <div className="field-error">{errors.from}</div>
                    )}
                  </label>
                  {/* <label htmlFor="city" className="label1">
                    <div className="text-content">
                      Doctor Type <span className="required">*</span>
                    </div>
                    <Select
                      name="doctorType"
                      options={doctorTypeOption}
                      getOptionLabel={(options) => {
                        return options["label"];
                      }}
                      getOptionValue={(options) => {
                        return options["value"];
                      }}
                      onChange={(item) => {
                        setFieldValue("doctorType", item.value);
                      }}
                      placeholder="Select Doctor type"
                    />
                    {touched.doctorType && errors.doctorType && (
                      <div className="field-error">{errors.doctorType}</div>
                    )}
                  </label> */}
                  <label className="label1">
                    <div className="text-content">
                      To <span className="required">*</span>
                    </div>
                    <Field
                      className="input-field"
                      name="to"
                      placeholder="Enter To Value"
                    />
                    {touched.to && errors.to && (
                      <div className="field-error">{errors.to}</div>
                    )}
                  </label>
                  <label className="label1">
                    <div className="text-content">
                      Event <span className="required">*</span>
                    </div>
                    <Field
                      className="input-field"
                      name="event"
                      placeholder="Enter Event Value"
                    />
                    {touched.event && errors.event && (
                      <div className="field-error">{errors.event}</div>
                    )}
                  </label>
                  <label className="label1">
                    <div className="text-content">
                      Notification Type <span className="required">*</span>
                    </div>
                    <Field
                      className="input-field"
                      name="notificationType"
                      placeholder="Enter Event Value"
                    />
                    {touched.notificationType && errors.notificationType && (
                      <div className="field-error">
                        {errors.notificationType}
                      </div>
                    )}
                  </label>
                </div>
                <label style={{ width: "66%" }} className="label1">
                  <div className="text-content">
                    Notification Body <span className="required">*</span>
                  </div>
                  <Field
                    as="textarea"
                    style={{ height: "80px" }}
                    className="input-field"
                    name="body"
                    placeholder="Enter Notification Body"
                  />
                  {touched.body && errors.body && (
                    <div className="field-error">{errors.body}</div>
                  )}
                </label>
                <div className="flex justify-center items-center mt-12">
                  <button
                    type="submit"
                    className="text-sm font-thin text-white bg-[#017C57] py-2 px-4  rounded-full border-none cursor-pointer"
                  >
                  SAVE DETAILS
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditNotificationForm;
