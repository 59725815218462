import React, { useEffect, useState } from "react";
import AdmissionDocuments from "../updatedocuments/admissionDocuments";
import DuringTreatmentDocuments from "../updatedocuments/duringTreatmentDocuments";
import OnDischargeDocuments from "../updatedocuments/onDischargeDocuments";
import EnhancementDocuments from "../updatedocuments/EnhancementDocuments";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import { docUploadRefreshContext } from "../contexts/CaseContexts";
import { logOut } from "../../../config/user";

import "./AddDocuments.scss";

const AddDocuments = ({ selectedProcedureId, caseId }) => {
  const [procdureDocuments, setProcedureDocuments] = useState({
    ADMISSIONDOCUMENT: [],
    DURINGTREATMENTDOCUMENT: [],
    ONDISCHARGEDOCUMENT: [],
  });
  const [enhancementDocuments, setEnhancementDocuments] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const [latestStage, setLatestStage] = useState(null);
  const [latestEnhancement, setLatestEnhancement] = useState(null);
  async function getMappedDocuments() {
    try {
      setIsloading(true);
      let documents = await CaseServices.getMappedDocuments(caseId);
      if (documents?.data?.mappedDocuments) {
        setProcedureDocuments(documents?.data?.mappedDocuments);
        //Below code is for getting all the enhancements done
        const enhancementDocumentsData = [];
        for (let i in documents?.data?.mappedDocuments) {
          if (i.toLowerCase().includes("enhancement")) {
            enhancementDocumentsData.push({
              key: i,
              value: documents?.data?.mappedDocuments[i],
            });
          }
        }
        enhancementDocumentsData?.sort((a, b) => b.key.localeCompare(a.key));
        setEnhancementDocuments(enhancementDocumentsData);
      }
      setIsloading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
      setIsloading(false);
    }
  }

  const getLatestCaseStage = async () => {
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);

      if (response?.data?.currnetSubMasterStage[0]) {
        setLatestStage(response?.data?.currnetSubMasterStage[0]?.hsaStage);
        console.log("latest stage data:", response?.data);
        setLatestEnhancement(response?.data?.latestEnhancement);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getMappedDocuments();
    getLatestCaseStage();
  }, []);
  console.log("latest case stage:", latestStage);
  return (
    <div className="add-document-box">
      {!isLoading ? (
        <docUploadRefreshContext.Provider value={{ getMappedDocuments }}>
          {enhancementDocuments &&
          enhancementDocuments.length > 0 &&
          latestStage &&
          latestStage?.toLowerCase().includes("enhancement") ? (
            <EnhancementDocuments
              ENHANCEMENTDOCUMENTS={enhancementDocuments}
              latestEnhancement={latestEnhancement}
            />
          ) : (
            <>
              {latestStage &&
                latestStage?.toLowerCase().includes("admission") && (
                  <AdmissionDocuments
                    ADMISSIONDOCUMENT={procdureDocuments?.ADMISSIONDOCUMENT}
                  />
                )}

              {/* <DuringTreatmentDocuments
                DURINGTREATMENTDOCUMENT={
                  procdureDocuments?.DURINGTREATMENTDOCUMENT
                }
              /> */}
              {((latestStage && latestStage?.toLowerCase().includes("claim")) ||
                latestStage?.toLowerCase().includes("discharge")) && (
                <OnDischargeDocuments
                  ONDISCHARGEDOCUMENT={procdureDocuments?.ONDISCHARGEDOCUMENT}
                />
              )}
            </>
          )}
        </docUploadRefreshContext.Provider>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AddDocuments;
