import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function newInterUser(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/create-team-user`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function getTeamUser(limit, page) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/get-team-user?role=team&limit=${limit}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}
function getUserResponsibilityList(limit, page) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/user-responsibilities?limit=${limit}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}
function mapUserResponsibilities(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/map-user-responsibility`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

export const InterUserMangementServices = {
  newInterUser,
  getTeamUser,
  getUserResponsibilityList,
  mapUserResponsibilities,
};
