import { useState } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
import { CircularProgress } from "@mui/material";
const RejectionPopUp = ({ isOpen, selectedDoc, getDocumentsData }) => {
  const [status, setStatus] = useState("REJECTED");
  const [rejectionReason, setRejectionReason] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const handleRejectDocument = async () => {
    setLoading(true);
    const data = [
      {
        documentId: selectedDoc.id,
        status: status,
        rejectionReason: rejectionReason,
      },
    ];
    if (rejectionReason) {
      try {
        await PreinitiateServices.updateReferenceDocumentStatus(
          data,
          selectedDoc.caseId
        );
        window.alert("Document Rejected Successfully");
        isOpen(false);
        getDocumentsData();
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("Rejection Reason Required");
    }

    setLoading(false);
  };
  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-[#017C5780] z-30">
      <div className="relative bg-white rounded-[16px] w-[90%] h-[70%] xl:w-[50%] xl:h-[75%] p-4 xl:p-8">
        <div
          className="absolute top-4 right-4 xl:top-7 xl:right-8 cursor-pointer z-20"
          onClick={() => isOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>

        <div className=" h-[80%] w-full flex justify-center">
          {selectedDoc?.documentUrl?.slice(-3) === "pdf" ? (
            <iframe
              src={`http://docs.google.com/gview?url=${selectedDoc.documentUrl}&embedded=true`}
              fgrameborder="0"
              height="100%"
              width="100%"
            />
          ) : (
            <img
              src={selectedDoc.documentUrl}
              alt="doc-image"
              className="w-[100%] h-[100%] object-contain"
              // className="h-[70%]"
            />
          )}
          <a
            href={selectedDoc.documentUrl}
            className="absolute top-5 right-12 xl:top-8 xl:right-16  cursor-pointer"
          >
            <img src={DownloadIcon} alt="download-icon" />
          </a>
        </div>
        <div className="flex justify-center xl:mt-8 ">
          <input
            type="text"
            placeholder="Rejection Reason"
            style={{ border: "1px solid #e4e4e4" }}
            className="h-[40px] font-semibold text-sm text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%]"
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </div>
        <div className=" mt-4 xl:mt-8">
          {isLoading ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : confirmation ? (
            <div className="flex justify-around items-center">
              <div className="text-xl font-medium text-[#191919]">
                Are you sure you want to reject
              </div>{" "}
              <div className="text-sm flex gap-2">
                <div
                  className="text-xs text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
                  onClick={() => setConfirmation(false)}
                >
                  NO
                </div>
                <div
                  className="text-xs text-white bg-[#017C57] px-8 py-2 rounded-full cursor-pointer"
                  onClick={handleRejectDocument}
                >
                  YES
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div
                className="text-xs text-white bg-[#017C57] h-fit w-fit cursor-pointer rounded-full px-12 py-2"
                onClick={() => setConfirmation(true)}
              >
                REJECT
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectionPopUp;
