import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getDashboardData(status) {
    return axios({
      method: "Post",
      url: `${REACT_APP_API_URL}/dashboard`,
      data:{status:[status]},
      headers: getAuthorizationToken(),
    });
  }

  export const dashboardServices = {
    getDashboardData,
  }
