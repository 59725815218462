import React, { useEffect } from "react";
import { pdfjs } from "react-pdf";
import { AuthServices } from "../services/AuthServices";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function Testingimage() {
  const [pdf, setPdf] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pdfRendering, setPdfRendering] = React.useState(false);
  const [pageRendering, setPageRendering] = React.useState(false);

  async function showPdf(event) {
    try {
      setPdfRendering(true);
      const file = event.target.files[0];

      const uri = URL.createObjectURL(file);

      const _PDF_DOC = await pdfjs.getDocument(uri).promise;

      setPdf(_PDF_DOC);
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
      setPdfRendering(false);
    }
  }

  function changePage(pageNumber) {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  }

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];

    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");

    try {
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);

        const viewport = page.getViewport({ scale: 3 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        //   setWidth(viewport.width);
        //   setHeight(viewport.height);

        await page.render(renderContext).promise;

        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);

            imagesList.push(blobUrl);

            if (i === pdf.numPages) {
              setImages(imagesList);
            }
          }
        }, "image/png");
      }
    } catch (error) {
    } finally {
      setPageRendering(false);
    }
  }

  const uploadTestImage = async () => {
    let data = new FormData();
    const uploadTestImage = async () => {
      const data = new FormData();

      for (let index = 0; index < images.length; index++) {
        let image = images[index];

        if (!(image instanceof Blob)) {
          const response = await fetch(image);
          image = await response.blob();
        }

        data.append("images", image, `image-${index + 1}.png`);
      }

      try {
        let res = await AuthServices.uploadPdfToImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
        console.error("Upload failed:", error);
      }
    };
  };

  useEffect(() => {
    pdf && renderPage();
  }, [pdf, currentPage]);

  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "5px",
    },
    imageWrapper: {
      border: "1px solid rgba(0,0,0,0.15)",
      borderRadius: "3px",
      boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
      padding: "0",
    },
  };

  return (
    <div style={{ marginTop: 100 }}>
      <button
        id="upload-button"
        onClick={() => document.getElementById("file-to-upload").click()}
      >
        Select PDF or Image
      </button>
      <input
        type="file"
        id="file-to-upload"
        accept="application/pdf, image/*"
        hidden
        onChange={showPdf}
      />
      <div id="pdf-main-container">
        <div id="pdf-loader" hidden={!pdfRendering}>
          Loading document ...
        </div>
        <div id="page-count-container">
          Page {currentPage} of <div id="pdf-total-pages">{totalPages}</div>
        </div>
        <div id="pdf-contents">
          <div id="pdf-meta">
            <div id="pdf-buttons">
              <button id="pdf-prev" onClick={() => changePage(currentPage - 1)}>
                Previous
              </button>
              <button id="pdf-next" onClick={() => changePage(currentPage + 1)}>
                Next
              </button>
            </div>
          </div>
          <div id="image-canvas-row">
            <div style={styles.wrapper}>
              {images.map((image, idx) => (
                <div key={idx} style={styles.imageWrapper}>
                  <img
                    id="image-generated"
                    src={image}
                    alt="Generated PDF or Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "0",
                      border: "none",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div id="page-loader" hidden={!pageRendering}>
            Loading page ...
          </div>
          <button>Show PNG</button>
          <button
            onClick={() => {
              uploadTestImage();
            }}
          >
            Download PNG
          </button>
        </div>
      </div>
    </div>
  );
}
