import React, { useEffect, useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { RecordingServices } from "../services/RecordingServices";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import { CircularProgress } from "@mui/material";
const AllCaseRecording = () => {
  const [recordingData, setRecordingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const getCaseRecordings = async () => {
    try {
      setLoading(true);
      const response = await RecordingServices.getAllCallRecordings();

      setRecordingData(response.data);
      setLoading(false);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCaseRecordings();
  }, []);

  const handlePlayAudio = (recording) => {
    if (!audioPlayer) {
      const newAudioPlayer = new Audio(recording.recordingurl);
      setAudioPlayer({ recordingId: recording.id, player: newAudioPlayer });
      newAudioPlayer.play();
    } else if (audioPlayer.recordingId !== recording.id) {
      audioPlayer.player.pause();
      audioPlayer.player.currentTime = 0;
      const newAudioPlayer = new Audio(recording.recordingurl);
      setAudioPlayer({ recordingId: recording.id, player: newAudioPlayer });
      newAudioPlayer.play();
    } else if (audioPlayer.player.paused) {
      audioPlayer.player.play();
    } else {
      // If the audio is already playing, pause it
      audioPlayer.player.pause();
    }
  };

  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Cases
      </span>
      <div className="text-2xl text-[#191919] font-semibold">
        Case Recordings
      </div>
      <div className="grid grid-cols-4 text-[#191919] font-semibold my-4  ">
        <div className="pl-4">CaseId</div>
        <div className="pl-4">From</div>
        <div className="pl-4">Members</div>
        <div className="text-center">Recording</div>
      </div>
      {loading ? (
        <div className="flex justify-center mt-8">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {recordingData?.map((recording, idx) => {
            return (
              <div
                key={idx}
                className={`grid grid-cols-4 gap-y-3 py-2 rounded-[8px]   ${
                  idx % 2 === 0 ? "bg-[#F5F5F5]" : "bg-white"
                }`}
              >
                <div className="pl-4">{recording.caseId}</div>
                <div className="pl-4">{recording.from}</div>

                <div className="pl-4">
                  {Array.isArray(recording?.members)
                    ? recording?.members?.join(", ")
                    : recording.members}
                </div>
                <div className="flex justify-center">
                  <VolumeUpIcon
                    style={{
                      color:
                        audioPlayer?.recordingId === recording.id
                          ? "#017C57"
                          : "#000",
                      cursor: "pointer",
                    }}
                    onClick={() => handlePlayAudio(recording)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AllCaseRecording;
