import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { Field, Form, Formik } from "formik";
import { PatientServices } from "../../../services/PatientServices";
import { logOut } from "../../../config/user";
import * as Yup from "yup";
import Modal from "@mui/material/Modal";
import "./FamilyHistoryModal.scss";

export default function FamilyHistoryModal({
  patientId,
  updated,
  setUpdated,
  functionalityType,
  patientDetails,
}) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 640 ? "676px" : "328px",
    height: "700px",
    borderRadius: "16px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    overflowY: "auto",
    p: width > 640 ? "48px" : "16px",
  };

  const headStyle = {
    color: "#191919",
    fontFamily: "inter",
    fontSize: "24px",
    fontWeight: "500",
    marginTop: "40px",
  };

  const subHeadStyle = {
    paddingLeft: "8px",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: "400",
    color: "#797979",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginTop: "32px",
  };

  const flexStyle = {
    display: width > 640 ? "flex" : "block",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  const boxStyle = {
    marginTop: "72px",
    border: "1px solid #E4E4E4",
    backgroundColor: "#F9F9F9",
    fontFamily: "inter",
    width: width > 640 ? "49%" : "100%",
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const buttonStyle = {
    height: "34px",
    width: "160px",
    borderRadius: "32px",
    backgroundColor: "#017c57",
    border: "none",
    color: "white",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: "500",
    margin: "64px",
  };

  let initialValues = {
    pastIllness: {
      patientId: patientId,
      endocrineDiseases: null,
      diabetes: null,
      hypertension: null,
      cad: null,
      asthma: null,
      tuberculosis: null,
      stroke: null,
      cancers: null,
      bloodTransfusion: null,
      surgeries: null,
      others: null,
    },
    familyHistory: {
      patientId: patientId,
      diabetes: null,
      hypertension: null,
      stroke: null,
      cancers: null,
      tuberculosis: null,
      endocrineDiseases: null,
      asthma: null,
      otherHereditaryDisease: null,
      psychiatristIllness: null,
      others: null,
    },
  };

  if (
    functionalityType === "EDIT" &&
    patientDetails &&
    patientDetails?.familyhistories &&
    patientDetails?.familyhistories.length > 0
  ) {
    initialValues["familyHistory"] = {
      ...patientDetails?.familyhistories[0],
      patientId: patientId,
    };
  }

  if (
    functionalityType === "EDIT" &&
    patientDetails &&
    patientDetails?.pastillnesses &&
    patientDetails?.pastillnesses.length > 0
  ) {
    initialValues["pastIllness"] = {
      ...patientDetails?.pastillnesses[0],
      patientId: patientId,
    };
  }

  const errorValidation = Yup.object({
    pastIllness: Yup.object({
      endocrineDiseases: Yup.boolean().nullable(),
      diabetes: Yup.boolean().nullable(),
      hypertension: Yup.boolean().nullable(),
      cad: Yup.boolean().nullable(),
      asthma: Yup.boolean().nullable(),
      tuberculosis: Yup.boolean().nullable(),
      stroke: Yup.boolean().nullable(),
      cancers: Yup.boolean().nullable(),
      bloodTransfusion: Yup.boolean().nullable(),
      surgeries: Yup.boolean().nullable(),
      others: Yup.boolean().nullable(),
    }).test(
      "at-least-one-pastIllness",
      "At least one past illness must be selected",
      (value) => Object.values(value).some((val) => val === true)
    ),
    familyHistory: Yup.object({
      diabetes: Yup.boolean().nullable(),
      hypertension: Yup.boolean().nullable(),
      stroke: Yup.boolean().nullable(),
      cancers: Yup.boolean().nullable(),
      tuberculosis: Yup.boolean().nullable(),
      endocrineDiseases: Yup.boolean().nullable(),
      asthma: Yup.boolean().nullable(),
      otherHereditaryDisease: Yup.boolean().nullable(),
      psychiatristIllness: Yup.boolean().nullable(),
      others: Yup.boolean().nullable(),
    }).test(
      "at-least-one-familyHistory",
      "At least one family history must be selected",
      (value) => Object.values(value).some((val) => val === true)
    ),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      await PatientServices.addPastIllness({
        ...values.pastIllness,
        patientId: patientId || "55f91d70-24e3-4e98-8881-b12a1de29bcb",
      });
      await PatientServices.addFamilyHistory({
        ...values.familyHistory,
        patientId: patientId || "55f91d70-24e3-4e98-8881-b12a1de29bcb",
      });

      window.alert("Data Saved");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async (values) => {
    setIsSubmitting(true);
    try {
      await PatientServices.updatePastIllness(
        values.pastIllness,
        values?.pastIllness?.id
      );
      await PatientServices.updateFamilyHistory(
        values.familyHistory,
        values?.familyHistory?.id
      );

      setUpdated(!updated);
      window.alert("Data updated");
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="family-modal-container">
      <button className="outline-btn" type="button" onClick={handleOpen}>
        Past & Family History
        <LaunchOutlinedIcon style={{ height: "14px" }} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box sx={style}>
          <Formik
            initialValues={initialValues}
            validationSchema={errorValidation}
            onSubmit={
              functionalityType === "EDIT" ? handleUpdate : handleSubmit
            }
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div style={flexStyle}>
                  <div style={boxStyle}>
                    <div style={headStyle}>History of past illness</div>
                    {errors.pastIllness && touched.pastIllness && (
                      <div style={{ color: "red" }} className="error">
                        {errors.pastIllness}
                      </div>
                    )}
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="pastIllness.endocrineDiseases"
                      />
                      Endocrine Diseases
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.diabetes" />
                      Diabetes
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.hypertension" />
                      Hypertension
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.cad" />
                      CAD
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.asthma" />
                      Asthma
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.tuberculosis" />
                      Tuberculosis
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.stroke" />
                      Stroke
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.cancers" />
                      Cancers
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="pastIllness.bloodTransfusion"
                      />
                      Blood Transfusion
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.surgeries" />
                      Surgeries
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="pastIllness.others" />
                      Others
                    </label>
                  </div>
                  <div
                    style={{
                      ...boxStyle,
                      marginTop: width > 640 ? "72px" : "16px",
                    }}
                  >
                    <div style={headStyle}>Family History</div>
                    {errors.familyHistory && touched.familyHistory && (
                      <div style={{ color: "red" }} className="error">
                        {errors.familyHistory}
                      </div>
                    )}
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="familyHistory.diabetes" />
                      Diabetes
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="familyHistory.hypertension"
                      />
                      Hypertension
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="familyHistory.stroke" />
                      Stroke
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="familyHistory.cancers" />
                      Cancers
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="familyHistory.tuberculosis"
                      />
                      Tuberculosis
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="familyHistory.endocrineDiseases"
                      />
                      Endocrine Diseases
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="familyHistory.asthma" />
                      Asthma
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="familyHistory.otherHereditaryDisease"
                      />
                      Other hereditary disease
                    </label>
                    <label style={subHeadStyle}>
                      <Field
                        type="checkbox"
                        name="familyHistory.psychiatristIllness"
                      />
                      Psychiatrist illness
                    </label>
                    <label style={subHeadStyle}>
                      <Field type="checkbox" name="familyHistory.others" />
                      Others
                    </label>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    style={buttonStyle}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
