import React from "react";
import DocumentDetailBox from "./documentDetailBox";
import "./admissionDocuments.scss";

const AdmissionDocuments = ({ ADMISSIONDOCUMENT }) => {
  let documents = ADMISSIONDOCUMENT;
  return (
    <div className="update-document-box">
      <div className="heading">
        Admission Document {/* <MapDocumentModal/> */}
      </div>
      <hr className="line" />
      <div className="sub-container">
        {documents && documents.length > 0 ? (
          documents &&
          documents.length > 0 &&
          documents.map((document, idx) => {
            return <DocumentDetailBox key={idx} docDetails={document} />;
          })
        ) : (
          <div className="sub-heading"> No document mapped</div>
        )}
      </div>
    </div>
  );
};

export default AdmissionDocuments;
