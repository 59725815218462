import { useState, useEffect } from "react";
import { RecordingServices } from "../../../services/RecordingServices";
import { CircularProgress } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CloseIcon from "../../../assets/crossIcon.svg";
const CaseRecordings = ({ caseId, isOpen }) => {
  const [recordingData, setRecordingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const getCaseRecordings = async () => {
    setLoading(true);
    try {
      const response = await RecordingServices.getCallRecordings(caseId);
      // const response = await RecordingServices.getCallRecordings("asdad");

      setRecordingData(response.data);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  const handlePlayAudio = (recording) => {
    // Create a new audio element if none exists or  if it has ended
    if (!audioPlayer) {
      const newAudioPlayer = new Audio(recording.recordingurl);
      setAudioPlayer({ recordingId: recording.id, player: newAudioPlayer });
      newAudioPlayer.play();
    }
    //if another audio is clicked
    else if (audioPlayer.recordingId !== recording.id) {
      audioPlayer.player.pause();
      audioPlayer.player.currentTime = 0;
      const newAudioPlayer = new Audio(recording.recordingurl);
      setAudioPlayer({ recordingId: recording.id, player: newAudioPlayer });
      newAudioPlayer.play();
    } else if (audioPlayer.player.paused) {
      // If the audio player exists and is paused, resume the audio
      audioPlayer.player.play();
    } else {
      // If the audio is already playing, pause it
      audioPlayer.player.pause();
    }
  };
  const handleClose = () => {
    isOpen(null);
    // if (!audioPlayer.player.paused) {
    //   audioPlayer.player.stop();
    // }
    if (audioPlayer) {
      audioPlayer.player.pause();
    }
    setAudioPlayer(null);
  };
  useEffect(() => {
    getCaseRecordings();
  }, []);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-5 left-0 w-screen h-screen flex justify-center items-center bg-[#017C5780] z-10">
      <div className="relative bg-white rounded-[16px] w-[90%] xl:w-[70%] h-max-[30%] p-4 xl:p-8">
        <div className="absolute flex gap-2 top-4 right-4 xl:top-7 xl:right-8 cursor-pointer">
          <img
            src={CloseIcon}
            height={30}
            width={30}
            alt="close-icon"
            onClick={handleClose}
          />
        </div>
        <div className="text-2xl text-[#191919] font-semibold">
          Case Recordings
        </div>

        <div className="grid grid-cols-4 text-[#191919] font-semibold my-4">
          <div className="pl-4">CaseId</div>
          <div className="pl-4">From</div>
          <div className="pl-4">Members</div>
          <div className="text-center">Recording</div>
        </div>
        {loading ? (
          <div className="flex justify-center mt-8">
            <CircularProgress />
          </div>
        ) : (
          <div className="h-max-[80%] overflow-y-scroll pr-2">
            {recordingData?.map((recording, idx) => {
              return (
                <div
                  key={idx}
                  className={`grid grid-cols-4 gap-y-3 py-2 rounded-[8px]   ${
                    idx % 2 === 0 ? "bg-[#F5F5F5]" : "bg-white"
                  }`}
                >
                  <div className="pl-4">{recording.caseId}</div>
                  <div className="pl-4">{recording.from}</div>

                  <div className="pl-4">
                    {Array.isArray(recording?.members)
                      ? recording?.members?.join(", ")
                      : recording.members}
                  </div>
                  <div className="flex justify-center">
                    <VolumeUpIcon
                      style={{
                        color:
                          audioPlayer?.recordingId === recording.id
                            ? "#017C57"
                            : "#000",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlayAudio(recording)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseRecordings;
