import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { HorizontalFormStepper } from "../../../utils/formUtils";
import AddHospitalDetails from "../addcases/AddHospitalDetails";
import EditStatusModal from "./EditStatusModal";
import EditStatusClaimModal from "./EditStatusClaimModal";
import AddPatientDetails from "../addcases/AddPatientDetails";
import AddCaseDetails from "../addcases/AddCaseDetails";
import MapDocumentsNewPage from "../mapdocuments/MapDocumentsNewPage";
import AddDocuments from "../addcases/AddDocuments";
import AddEnhancement from "../addEnhancement/AddEnhancement";
import AddDischarge from "../addDischarge/AddDischarge";
import CaseHistory from "../casehistory/CaseHistory";
import DownloadDocuments from "../downloadDocuments/DownloadDocuments";

import TabStepperUtil from "../../../utils/tabStepperUtil";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import EditIcon from "../../../assets/editIconWhite.svg";
import DownArrow from "../../../assets/downArrowIcon.svg";
import "./EditCasesDashboard.scss";
import { CaseServices } from "../../../services/CaseServices";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { CaseContext } from "../contexts/CaseContexts";
import { logOut } from "../../../config/user";
import { useSelector, useDispatch } from "react-redux";
import { setOutsideUser } from "../../../redux/roleSlice";
let componentWithoutPage = ["MAP DOCUMENTS", "CASE HISTORY"];
const steps = [
  "Initiate Admission",
  "Admission Approved",
  "Enhancement Initiated",
  "Enhancement Approved",
  "Discharge Initiated",
  "Claim Initiated",
  "Claim Approved",
];

const EditCasesDashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [notificationCaseId, setNotificationCaseId] = useState(
    searchParams.get("caseId")
  );
  const selectedTab = useSelector((state) => state.currentRole.selectedTab);
  const [value, setValue] = React.useState(
    selectedTab ? selectedTab : "HOSPITAL DETAILS"
  );
  const [caseId, setCaseId] = useState(
    searchParams.get("caseId")
      ? searchParams.get("caseId")
      : location?.state?.selectedCase?.id
  );
  const [patientId, setPatientId] = useState(
    searchParams.get("caseId") ? "" : location?.state?.selectedCase?.patientId
  );
  const [caseDetails, setCaseDetails] = useState(
    searchParams.get("caseId") ? null : location?.state?.selectedCase
  );

  const [hospitalid, sethospitalid] = useState(
    searchParams.get("caseId") ? "" : location?.state?.selectedCase?.hospitalId
  );
  const [patientDetails, setPatientDetails] = useState(
    searchParams.get("caseId") ? "" : location?.state?.selectedCase?.patient
  );
  const [activeStep, setActiveStep] = useState(
    caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
      "admission in progress"
      ? 0
      : caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
        "admission approved"
      ? 1
      : caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
        "enhancement in progress"
      ? 2
      : caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
        "enhancement approved"
      ? 3
      : caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
        "claim in progress"
      ? 5
      : caseDetails?.submasterstagestatuses[0]?.hsaStage.toLowerCase() ===
        "claim approved"
      ? 6
      : -1
  );
  const [editStatus, setEditStatus] = useState(false);
  const currentRole = useSelector((state) => state.currentRole.value);

  async function refreshPage() {
    try {
      let res = await CaseServices.getCaseDetails(caseId);
      if (res?.data?.caseDetails) {
        setCaseDetails(res?.data?.caseDetails);
        sethospitalid(res?.data?.caseDetails?.hospitalId);
        setPatientDetails(res?.data?.caseDetails?.patient);
        setPatientId(res?.data?.caseDetails?.patientId);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  }
  useEffect(() => {
    if (notificationCaseId) {
      refreshPage();
    }
  }, [notificationCaseId]);
  let children = [];
  if (
    caseDetails?.submasterstagestatuses[0]?.hsaStage ===
      "Enhancement Approved" ||
    caseDetails?.submasterstagestatuses[0]?.hsaStage === "Admission Approved"
  ) {
    children = [
      <AddHospitalDetails
        refreshPage={refreshPage}
        setValue={setValue}
        hospitalDetails={caseDetails?.hospital}
        setCaseId={setCaseId}
        sethospitalid={sethospitalid}
        caseId={caseId}
        functionalityType="EDIT"
      />,
      <AddPatientDetails
        refreshPage={refreshPage}
        setValue={setValue}
        patientDetails={caseDetails?.patient}
        setCaseId={setCaseId}
        setPatientId={setPatientId}
        hospitalid={hospitalid}
        functionalityType="EDIT"
      />,
      <AddCaseDetails
        refreshPage={refreshPage}
        setValue={setValue}
        setCaseId={setCaseId}
        patientId={patientId}
        setPatientId={setPatientId}
        caseDetails={caseDetails}
        patientDetails={patientDetails}
        setPatientDetails={setPatientDetails}
        functionalityType="EDIT"
      />,
      <AddEnhancement caseDetails={caseDetails} setValue={setValue} />,
      <AddDischarge caseDetails={caseDetails} setValue={setValue} />,
      <MapDocumentsNewPage
        refreshPage={refreshPage}
        setValue={setValue}
        setCaseId={setCaseId}
        csProcedures={caseDetails?.caseprocedures}
        caseId={caseId}
        functionalityType="EDIT"
      />,
      <AddDocuments caseId={caseId} functionalityType="EDIT" />,
      <CaseHistory functionalityType="EDIT" />,
      <DownloadDocuments caseId={caseId} />,
    ];
  } else {
    children = [
      <AddHospitalDetails
        refreshPage={refreshPage}
        setValue={setValue}
        hospitalDetails={caseDetails?.hospital}
        setCaseId={setCaseId}
        sethospitalid={sethospitalid}
        caseId={caseId}
        functionalityType="EDIT"
      />,
      <AddPatientDetails
        refreshPage={refreshPage}
        setValue={setValue}
        patientDetails={caseDetails?.patient}
        setCaseId={setCaseId}
        setPatientId={setPatientId}
        hospitalid={hospitalid}
        functionalityType="EDIT"
      />,
      <AddCaseDetails
        refreshPage={refreshPage}
        setValue={setValue}
        setCaseId={setCaseId}
        patientId={patientId}
        setPatientId={setPatientId}
        caseDetails={caseDetails}
        patientDetails={patientDetails}
        setPatientDetails={setPatientDetails}
        functionalityType="EDIT"
      />,
      // <AddEnhancement caseDetails={caseDetails} setValue={setValue} />,
      <AddDischarge caseDetails={caseDetails} setValue={setValue} />,
      <MapDocumentsNewPage
        refreshPage={refreshPage}
        setValue={setValue}
        setCaseId={setCaseId}
        csProcedures={caseDetails?.caseprocedures}
        caseId={caseId}
        functionalityType="EDIT"
      />,
      <AddDocuments caseId={caseId} functionalityType="EDIT" />,
      <CaseHistory functionalityType="EDIT" />,
      <DownloadDocuments caseId={caseId} />,
    ];
  }

  let labels = ["HOSPITAL DETAILS", "PATIENT DETAILS"];
  if (
    caseDetails &&
    caseDetails.masterstagestatuses &&
    caseDetails.masterstagestatuses[0]?.masterStageStatus === "ACTIVE"
  ) {
    if (
      caseDetails &&
      caseDetails.caseprocedures &&
      caseDetails.caseprocedures.length > 0
    ) {
      if (
        caseDetails.submasterstagestatuses[0]?.hsaStage ===
          "Enhancement Approved" ||
        caseDetails.submasterstagestatuses[0]?.hsaStage === "Admission Approved"
      ) {
        labels = [
          "HOSPITAL DETAILS",
          "PATIENT DETAILS",
          "CASE DETAILS",
          "ENHANCEMENT",
          "DISCHARGE",
          "MAP DOCUMENTS",
          "UPDATE DOCUMENTS",
          "CASE HISTORY",
          "DOWNLOAD DOCUMENTS",
        ];
      } else {
        labels = [
          "HOSPITAL DETAILS",
          "PATIENT DETAILS",
          "CASE DETAILS",
          "DISCHARGE",
          "MAP DOCUMENTS",
          "UPDATE DOCUMENTS",
          "CASE HISTORY",
          "DOWNLOAD DOCUMENTS",
        ];
      }
    } else {
      labels = ["HOSPITAL DETAILS", "PATIENT DETAILS", "CASE DETAILS"];
    }
  }

  dispatch(setOutsideUser(false)); // removed outside user value from store
  return (
    <div className="edit-case-dashboard">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; EditCases
      </span>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="heading">Edit Cases</div>
        <div>
          {currentRole?.role?.roleName === "TeamA" ? (
            <div className="flex gap-4">
              <div>
                {" "}
                <select
                  name=""
                  id=""
                  style={{ border: "1px solid #017C57" }}
                  className="px-4 py-2 text-[#017C57] text-sm font-semibold rounded-full"
                >
                  <option value="">
                    ASSIGN MANAGER
                    <span>
                      <img src={DownArrow} alt="down-arrow-icon" />
                    </span>
                  </option>
                  <option value="">MANAGER 1</option>
                  <option value="">MANAGER 2</option>
                  <option value="">MANAGER 3</option>
                </select>
              </div>
            </div>
          ) : (
            <div
              style={{
                background: "#017C57",
                color: "white",
                fontSize: "14px",
                fontWeight: "600",
                paddingBlock: "8px",
                paddingInline: "16px",
                borderRadius: "32px",
                display: "flex",
                gap: "12px",
                cursor: "pointer",
              }}
              onClick={() => setEditStatus(true)}
            >
              <img src={EditIcon} alt="edit-icon" />
              EDIT STATUS
            </div>
          )}

          {editStatus && (
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                height: "100vh",
                width: "100vw",
                background: "#017C5780",
                zIndex: "20",
                overflowY: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {caseDetails?.submasterstagestatuses[0]?.commandCenterStage
                .toLowerCase()
                .includes("claim") ? (
                <EditStatusClaimModal
                  setEditStatus={setEditStatus}
                  caseId={caseId}
                  caseDetails={caseDetails}
                />
              ) : (
                <EditStatusModal
                  setEditStatus={setEditStatus}
                  caseId={caseId}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "20px",
        }}
      />
      <Paper className="stepper-box">
        <HorizontalFormStepper steps={steps} activeStep={activeStep} />
      </Paper>
      <CaseContext.Provider value={{ roleName: currentRole?.role?.roleName }}>
        <TabStepperUtil
          children={children}
          labels={labels}
          value={value}
          setValue={setValue}
          componentWithoutPage={componentWithoutPage}
          handleChageDisabled="false"
        />
      </CaseContext.Provider>
      {/* <PhoneSupport /> */}
    </div>
  );
};

export default EditCasesDashboard;
