import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import { styled } from "@mui/material/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: ownerState.completed ? "#017C57" : "#E4E4E4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  ...(ownerState.active && {
    color: "#017C57",
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, icon } = props;
  const icons = {
    1: <TripOriginIcon />,
    2: <TripOriginIcon />,
    3: <TripOriginIcon />,
    4: <TripOriginIcon />,
    5: <TripOriginIcon />,
    6: <TripOriginIcon />,
  };

  return (
    <CustomStepIconRoot ownerState={{ completed, active }}>
      {icons[String(icon)]}
    </CustomStepIconRoot>
  );
}

export const HorizontalFormStepper = ({ steps, activeStep }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      style={
        width > 640
          ? { width: "100%" }
          : {
              width: "100%",
              overflowX: "scroll",
              // flexWrap: "wrap",
            }
      }
      sx={{
        "& .MuiStepIcon-root": {
          color: "#E4E4E4", // default color of icons
          "&.Mui-active": {
            color: "#017C57", // active icon color
          },
          "&.Mui-completed": {
            color: "#017C57", // completed icon color
          },
        },
        "& .MuiStepLabel-label": {
          fontFamily: "inherit",
          fontSize: "12px",
          fontWeight: "600",
          flexWrap: "wrap",
          color: "#191919", // default label color
          "&.Mui-active": {
            color: "#191919", // active label color
          },
          "&.Mui-completed": {
            color: "#191919", // completed label color
          },
        },
      }}
    >
      {steps.map((label, idx) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export const Suggestionbox = ({
  value,
  onChange,
  options,
  label,
  getOptionLabel,
  renderOption,
  defaultValue,
}) => {
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: "start",
    limit: 10,
  });
  return (
    <Autocomplete
      sx={{
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #e4e4e4",
        color: "#797979",
        fontSize: "18px",
        fontFamily: "Inter",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
        "& .MuiAutocomplete-inputRoot": {
          padding: "0px",
        },
        "& .MuiAutocomplete-popupIndicator": {
          color: "#797979",
        },
      }}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          placeholder="Search..."
          // You can add more props as needed
        />
      )}
    />
  );
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
