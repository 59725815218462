import React from "react";
import { razorPayValidation } from "../services/SubscriptionServices";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const RazorpayPayment = ({ orderId, amount, paymentDetails }) => {
  const openCheckout = () => {
    const options = {
      key: "rzp_test_BIFi9hJnEhjt4r", // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Settlemed", //your business name
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // callback_url: REACT_APP_API_URL,
      handler: async function (response) {
        const body = { ...response };
        try {
          const validationResponse = await razorPayValidation(
            JSON.stringify(body)
          );
          window.alert("Subscription Added Successfully");
          window.location.reload();
        } catch (error) {
          if (error?.response?.message) {
            window.alert(error?.response?.message);
          } else {
            window.alert(error);
          }
        }
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: paymentDetails.name, //your customer's name
        email: paymentDetails.email,
        contact: paymentDetails.phone, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Settlmed Noida office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <div>
      <div
        className="w-[110px] text-white bg-[#017C57] py-2 px-4 rounded-full text-center cursor-pointer"
        onClick={openCheckout}
      >
        Pay
      </div>
    </div>
  );
};

export default RazorpayPayment;
