import React from "react";
import "./dashboard.scss";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import TeamWiseStatus from "./teamWiseStatus";
import HospitalWiseStatus from "./hospitalWiseStatus";
import { useSelector } from "react-redux";
const Dashboard = () => {
  useSelector((state) => state.currentRole.value);

  return (
    <div className="box">
      <span className="path_line">
        {" "}
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard
      </span>
      <div className="heading">
        Your Dashboard <button className="filled-btn">+ ADD NEW</button>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />
      <div className="status-container">
        <HospitalWiseStatus />
        <TeamWiseStatus />
      </div>
    </div>
  );
};

export default Dashboard;
