import React, { useEffect, useState, useRef } from "react";
import UploadIcon from "../../assets/uploadIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import PreinitiateModal from "./preInitiateModal/PreinitiateModal";
import { Paper } from "@mui/material";
import Select from "react-select";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import EditIcon from "../../assets/EditCircleIcon.svg";
import TooltipIcon from "../../assets/tooltipTriangle.svg";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import CustomPagination from "../../utils/paginationUtils";
import { logOut, logOut401 } from "../../config/user";
import { CaseServices } from "../../services/CaseServices";
import { NotificationServices } from "../../services/NotificationServices";
import { HospitalServices } from "../../services/HospitalServices";
import "./caseList.scss";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CountdownTimer from "../../utils/countDownTimer";
import CaseRecordings from "./caseRecordings/CaseRecordings";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import {
  updateMember,
  updatePageNumber,
  updateStatus,
  updateSubStatus,
  updateSelectedHospital,
} from "../../redux/caseDetailsSlice";

const CaseListV2 = ({ token, caseId }) => {
  const [cases, setCases] = useState([1, 2, 3]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalFilteredCases, setTotalFilteredCases] = useState(0);
  const [totalSettlemedCases, setTotalSettlemedCases] = useState(0);
  const { pageNo, member, status, subStatus, selectedHospitalId } = useSelector(
    (state) => state.caseDetails
  );

  const [page, setPage] = useState(pageNo ? pageNo : 1);

  const [isLoading, setIsLoading] = useState(true);
  const [caseStatus, setCaseStatus] = useState(status ? status : "");
  const [nextStepUser, setNextStepUser] = useState(member ? member : "");
  const [commandCenterStage, setCommandCenterStage] = useState(
    subStatus ? subStatus : ""
  );
  const [searchKey, setSearchKey] = useState("");
  const [isPreinitiateOpen, setPreinitiateOpen] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [hospitalId, setHospitalId] = useState(
    selectedHospitalId ? selectedHospitalId : ""
  );
  const [selectedCase, setSelectedCase] = useState(null);
  const [showRecordings, setShowRecordings] = useState(null);

  const outsideUser = useSelector((state) => state.currentRole.isOutsideUser);
  const storeCaseId = useSelector((state) => state.caseDetails.caseId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let searchTimeRef = useRef();

  let limit = 10;
  const statusList = [
    { value: "", label: "All" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const getTime = (createdDate) => {
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(createdDate);

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days > 0 ? days + "D " : ""}${hours > 0 ? hours + "H " : ""} ${
      minutes > 0 ? minutes + "M" : ""
    } ${seconds}S`;
  };
  async function AllCases() {
    try {
      setIsLoading(true);
      let res = await CaseServices.allCases(
        searchKey,
        page,
        limit,
        caseStatus,
        caseId,
        hospitalId,
        nextStepUser,
        commandCenterStage
      );
      setCases(res?.data?.caseList);
      setTotalPages(res?.data?.totalPages);
      setTotalSettlemedCases(res?.data?.totalSettlemedCases);
      setTotalFilteredCases(res?.data?.totalFilteredCases);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut401();
        navigate("/");
      } else {
        window.alert(error);
      }
      setIsLoading(false);
    }
  }

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const handleInitiateOnTMS = async (cas) => {
    const postStageData = {
      hsaStage: cas?.submasterstagestatuses[0]?.commandCenterStage
        ?.toLowerCase()
        .includes("admission")
        ? "Admission Initiated on TMS"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("enhancement")
        ? "Enhancement Initiated on TMS"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("claim")
        ? "Claim Initiated on TMS"
        : "",
      commandCeneterStage: cas?.submasterstagestatuses[0]?.commandCenterStage
        ?.toLowerCase()
        .includes("admission")
        ? "Admission Initiated on TMS"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("enhancement")
        ? "Enhancement Initiated on TMS"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("claim")
        ? "Claim Initiated on TMS"
        : "",
      nextStepUser: cas?.submasterstagestatuses[0]?.commandCenterStage
        ?.toLowerCase()
        .includes("admission")
        ? "Internal team C"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("enhancement")
        ? "Internal Team E"
        : cas?.submasterstagestatuses[0]?.commandCenterStage
            ?.toLowerCase()
            .includes("claim")
        ? "Internal Team E"
        : "",
      caseDetails: [
        {
          query: "",
          queryResponse: "",
        },
      ],
    };
    try {
      const stageResponse = await CaseServices.updateSubMasterStage(
        cas.id,
        postStageData
      );
      window.alert(stageResponse.data.message);
      AllCases();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut401();
        navigate("/");
      } else {
        window.alert(error);
      }
    }
  };
  const calculatePrcentage = (uploaded, total) => {
    let percent = 0;
    if (total !== 0) {
      percent = (uploaded / total) * 100;
    }
    return percent;
  };
  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getRoleList = async () => {
    try {
      const response = await NotificationServices.getRoleList();
      setRoleList(response?.data?.allRoles);
    } catch (error) {
      window.alert(error);
    }
  };
  const getSubStatusList = async () => {
    try {
      const response = await NotificationServices.getSubMasterStages(130);
      setSubStatusList(response?.data?.subMasterStages);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    AllCases();
  }, [
    page,
    searchKey,
    hospitalId,
    caseStatus,
    nextStepUser,
    commandCenterStage,
    storeCaseId,
  ]);
  useEffect(() => {
    getHospitalList();
    getRoleList();
    getSubStatusList();
  }, []);

  useEffect(() => {
    dispatch(updateMember(nextStepUser));
  }, [nextStepUser]);
  useEffect(() => {
    dispatch(updateStatus(caseStatus));
  }, [caseStatus]);
  useEffect(() => {
    dispatch(updateSubStatus(commandCenterStage));
  }, [commandCenterStage]);
  useEffect(() => {
    dispatch(updateSelectedHospital(hospitalId));
  }, [hospitalId]);
  useEffect(() => {
    dispatch(updatePageNumber(page));
  }, [page]);

  return (
    <div className="case-list-container">
      <Paper style={{ backgroundColor: "#f5f5f5" }} className="list-paper">
        <div className="flex justify-between items-end">
          <div className="sub-heading">
            Sort by
            <Select
              name="teamMember"
              isClearable
              options={roleList}
              getOptionLabel={(options) => {
                return options["roleName"];
              }}
              getOptionValue={(options) => {
                return options["roleText"];
              }}
              onChange={(item) => {
                setPage(1);
                item ? setNextStepUser(item.roleText) : setNextStepUser("");
              }}
              value={roleList.filter(
                (option) => option.roleText === nextStepUser
              )}
              placeholder="TEAM - MEMBER"
            />
            <Select
              name="caseStatus"
              options={statusList}
              onChange={(item) => {
                setPage(1);
                setCaseStatus(item.value);
              }}
              value={statusList.filter((option) => option.value === caseStatus)}
              placeholder="STATUS"
            />
            <Select
              name="subStatus"
              isClearable
              options={subStatusList}
              getOptionLabel={(options) => {
                return options["commandCenterStageStatus"];
              }}
              getOptionValue={(options) => {
                return options["commandCenterStageStatus"];
              }}
              onChange={(item) => {
                setPage(1);
                item
                  ? setCommandCenterStage(item.commandCenterStageStatus)
                  : setCommandCenterStage("");
              }}
              value={subStatusList.filter(
                (item) => item.commandCenterStageStatus === commandCenterStage
              )}
              placeholder="SUB STATUS"
            />
            <Select
              name="hospitalId"
              isClearable
              options={hospitalList}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["id"];
              }}
              onChange={(item) => {
                setPage(1);
                item ? setHospitalId(item.id) : setHospitalId("");
              }}
              value={hospitalList.filter((item) => item.id === hospitalId)}
              placeholder="SELECT HOSPITAL"
            />
          </div>
          <div
            style={{ border: "1px solid #DEDEDE" }}
            className="flex items-center gap-2 px-4 py-[6px] rounded-[8px] bg-white"
          >
            <img src={SearchIcon} alt="search-icon" />
            <input
              type="text"
              className="outline-none border-0"
              onChange={handleSearch}
              placeholder="Search anything"
            />
          </div>
        </div>
        <div className="text-[18px] text-[#797979] mt-2">
          Total Cases{" "}
          <span className="font-semibold">
            {totalFilteredCases}
            {" / "}
            {totalSettlemedCases}
          </span>
        </div>
        {/* <div style={{ border: "1px solid #e5e5e5" }} className="mb-10 mt-6" /> */}

        {isLoading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-9 text-[#191919] font-semibold px-[14px] mt-6">
              <div>Beneficiary No.</div>
              <div>Patient Name</div>
              <div>Pmjay ID</div>
              <div>HUID</div>
              <div>Hospital Name</div>
              <div>Area</div>
              <div>City</div>
              <div>Gender/Age</div>
              <div>Registered Date</div>
            </div>
            <div className="h-screen overflow-y-scroll">
              {cases && cases.length > 0 ? (
                cases.map((cas, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1px solid #E5E5E5" }}
                      className="bg-white px-[14px] py-4 rounded-[8px] mt-6 mb-3 hovernow"
                    >
                      <div className="grid grid-cols-9 text-[#191919] mb-5">
                        <div className="flex gap-1 items-start">
                          <input
                            type="checkbox"
                            className="h-[16px] w-[16px]"
                          />
                          <div>{cas?.patient?.beneficiaryNo}</div>
                        </div>
                        <div>{cas?.patient?.patientName}</div>
                        <div>{cas?.pmjayId || "--"}</div>
                        <div>{cas?.hospitalId}</div>
                        <div>{cas?.hospital?.name}</div>
                        <div>Locality</div>
                        <div>{cas?.hospital?.city}</div>
                        <div>
                          {cas?.patient?.gender?.slice(0, 1)}/
                          {cas?.patient?.age}
                        </div>
                        <div>
                          {cas?.patient?.registeredDate && (
                            <DatePicker
                              selected={new Date(cas?.patient?.registeredDate)}
                              className="text-[#191919] font-medium border-none bg-transparent"
                              timeInputLabel="Time:"
                              dateFormat="dd/MM/yyyy h:mm aa"
                              showTimeInput
                              disabled
                            />
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center ">
                          <div
                            className="text-xs font-semibold px-4 py-1 rounded-full"
                            style={
                              cas?.masterstagestatuses &&
                              cas?.masterstagestatuses[0] &&
                              cas?.masterstagestatuses[0]?.masterStageStatus ===
                                "INACTIVE"
                                ? {
                                    backgroundColor: "#FFEAC6",
                                    color: "#9D6B2A ",
                                  }
                                : {
                                    backgroundColor: "#CDFAF4",
                                    color: "#017C57",
                                  }
                            }
                          >
                            {cas?.masterstagestatuses &&
                              cas?.masterstagestatuses[0] &&
                              cas?.masterstagestatuses[0]?.masterStageStatus}
                          </div>
                          <div
                            className={`${
                              cas?.submasterstagestatuses[0]?.commandCenterStage
                                .toLowerCase()
                                .includes("pending")
                                ? "text-[#9D6B2A] bg-[#FFEAC6]"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    .toLowerCase()
                                    .includes("rejected")
                                ? "text-[#CF3636] bg-[#FFD6DB]"
                                : "text-[#017C51] bg-[#CDFAF4]"
                            }  text-xs font-semibold uppercase rounded-full px-4 py-1`}
                          >
                            {cas?.submasterstagestatuses &&
                              cas?.submasterstagestatuses[0] &&
                              cas?.submasterstagestatuses[0]
                                ?.commandCenterStage}
                          </div>
                          <div className="ml-2">
                            {new Date(cas.deadline) == "Invalid Date" ? (
                              <div className="right-container"></div>
                            ) : (
                              <div className="flex text-sm text-[#797979] gap-1">
                                Time :
                                <div className="font-semibold text-[#191919]">
                                  <CountdownTimer deadline={cas.deadline} />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="h-1 w-1 bg-[#191919] rounded-full mx-2" />
                          <div className="text-[#797979] text-sm mx-2">
                            Team / NSU :{" "}
                            <span className="text-sm text-[#191919] font-semibold">
                              {cas?.submasterstagestatuses &&
                              cas?.submasterstagestatuses[0]?.nextStepUser
                                ? cas?.submasterstagestatuses[0].nextStepUser
                                : "---"}
                            </span>
                          </div>
                          <div className="text-[#797979] text-sm">
                            Current Stage Time :{" "}
                            <span className="text-sm text-[#191919] font-semibold">
                              {cas?.submasterstagestatuses[0]
                                ? getTime(
                                    cas?.submasterstagestatuses[0].createdAt
                                  )
                                : "--"}
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-3 items-center">
                          {cas?.submasterstagestatuses[0]?.commandCenterStage
                            ?.toLowerCase()
                            .includes("approved by") ? (
                            <div
                              style={{ border: "1px solid #017C57" }}
                              className="text-xs bg-white text-[#017C57] font-semibold px-3 py-1 rounded-full cursor-pointer"
                              onClick={() => {
                                console.log("Initiate on TMS");
                                handleInitiateOnTMS(cas);
                              }}
                            >
                              {cas?.submasterstagestatuses[0]?.commandCenterStage
                                ?.toLowerCase()
                                .includes("admission")
                                ? "Initiate Admission on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("enhancement")
                                ? "Initiate Enhancement on TMS"
                                : cas?.submasterstagestatuses[0]?.commandCenterStage
                                    ?.toLowerCase()
                                    .includes("claim")
                                ? "Initiate Claim on TMS"
                                : ""}
                            </div>
                          ) : (
                            <div
                              style={{ width: "46px" }}
                              // onClick={() =>
                              //   navigate("/dashboard/upload-documents", {
                              //     state: { caseId: cas?.id },
                              //   })
                              // }
                            >
                              <CircularProgressbarWithChildren
                                value={calculatePrcentage(
                                  cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments -
                                    cas?.admissionDocumentsPending -
                                    cas?.admissionDocumentsRejected -
                                    cas?.latestEnhancementPending -
                                    cas?.latestEnhancementRejected -
                                    cas?.onDischargePending -
                                    cas?.onDischargeRejected,
                                  cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments
                                )} // value in percentage
                                strokeWidth={13}
                                styles={{
                                  path: {
                                    stroke: "#017C57",
                                    strokeLinecap: "round",
                                    transformOrigin: "center center",
                                  },
                                  trail: {
                                    stroke: "#CDFAF4",
                                    transformOrigin: "center center",
                                  },
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: "10px",
                                    color: "#017C57",
                                    fontFamily: "inter, sans-serif",
                                    fontWeight: 500,
                                    // paddingBottom: "14px",
                                  }}
                                >
                                  {cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments -
                                    cas?.admissionDocumentsPending -
                                    cas?.admissionDocumentsRejected -
                                    cas?.latestEnhancementPending -
                                    cas?.latestEnhancementRejected -
                                    cas?.onDischargePending -
                                    cas?.onDischargeRejected}
                                  /
                                  {cas?.totalDocuments -
                                    cas?.totalDuringTreatmentDocuments}
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                          )}
                          <div
                            style={{ border: "1px solid #017C57" }}
                            className="group relative flex  gap-2 px-2 py-2 rounded-full"
                          >
                            <div className=" h-[14px] w-[14px] text-[10px] text-center text-[#FF1818] bg-[#FFC8C8] rounded-full">
                              {cas?.referenceDocumentsRejected}
                            </div>
                            <div className=" h-[14px] w-[14px] text-[10px] text-center  text-[#017C57] bg-[#CDFAF4] rounded-full">
                              {cas?.totalReferenceDocuments}
                            </div>
                            <img
                              src={UploadIcon}
                              alt="upload-icon"
                              className="w-[12px] cursor-pointer"
                              onClick={() => {
                                setPreinitiateOpen(true);
                                setSelectedCase(cas);
                              }}
                            />
                            <div className="w-[200%] hidden group-hover:block absolute text-xs top-[105%] -left-[50%] z-20">
                              <div className="w-full flex justify-center">
                                <img src={TooltipIcon} alt="toolti-icon" />
                              </div>
                              <div className="text-[12px] text-center text-white bg-[#017C57] py-2 px-1 rounded-[8px]">
                                Reference Documents
                              </div>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowRecordings(cas?.id)}
                          >
                            <PermPhoneMsgOutlinedIcon
                              style={{ color: "#017C57" }}
                            />
                          </div>
                          {showRecordings === cas?.id && (
                            <CaseRecordings
                              caseId={showRecordings}
                              isOpen={setShowRecordings}
                            />
                          )}
                          <div className="group relative">
                            <img
                              src={EditIcon}
                              alt="edit-icon"
                              className="cursor-pointer"
                              onClick={() => {
                                outsideUser
                                  ? navigate("/Dashboard/EditCases/v2", {
                                      state: { selectedCase: cas },
                                    })
                                  : navigate("/Dashboard/EditCases", {
                                      state: { selectedCase: cas },
                                    });
                              }}
                            />
                            <div className="w-[300%] hidden group-hover:block absolute text-xs top-[100%] -left-[90%] z-20">
                              <div className="w-full flex justify-center">
                                <img src={TooltipIcon} alt="tooltip-icon" />
                              </div>
                              <div className="text-[12px] text-center text-white bg-[#017C57] py-2 px-1 rounded-[8px]">
                                Edit Case
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-xl text-center font-semibold mt-8">
                  {" "}
                  No Records Found
                </div>
              )}
            </div>
          </div>
        )}
      </Paper>
      {isPreinitiateOpen && (
        <div>
          <PreinitiateModal
            selectedCase={selectedCase}
            isOpen={setPreinitiateOpen}
          />
        </div>
      )}
      <div className="list-pagination">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default CaseListV2;
