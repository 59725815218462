import { useState, useRef, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress } from "@mui/material";
import { logOut } from "../config/user";
import downloadPdf from "../utils/downloadPdfUtil";
import { AuthServices } from "../services/AuthServices";
import ImagesGallery from "../dashboard/cases/preInitiateModal/ImagesGallery";
import FolderIcon from "../assets/folderImage.jpg";
import DownloadIcon from "../assets/downloadIcon.svg";
import DownloadIconGreen from "../assets/downloadIconGreen.svg";
import EyeIcon from "../assets/eyeIcon.svg";
import "./EditDocuments.scss";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const EditDocuments = () => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [images, setImages] = useState([]);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const [percentage, setPercentage] = useState(0);
  const [netSpeed, setNetSpeed] = useState(0);
  const [selectAll, setSelectAll] = useState(true);
  const [selectNonDownloaded, setSelectNonDownloaded] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [openImagesGallery, setOpenImagesGallery] = useState(false);
  const fileInputRef = useRef(null);
  const dragDocument = useRef();
  const draggedOverDocument = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectNonDownloaded(false);
    const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
      (doc, idx) => {
        if (!selectAll) {
          return {
            ...doc,
            isSelected: true,
          };
        } else {
          return { ...doc, isSelected: false };
        }
      }
    );
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments(uploadedDocuments);
  };
  const handleSelectNonDownloaded = () => {
    setSelectNonDownloaded(!selectNonDownloaded);
    const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
      (doc, idx) => {
        if (!doc.isDownloaded) {
          if (selectNonDownloaded) {
            return {
              ...doc,
              isSelected: false,
            };
          } else {
            return {
              ...doc,
              isSelected: true,
            };
          }
        } else {
          return { ...doc, isSelected: false };
        }
      }
    );
    const totalSelected = modifiedData.filter((doc) => doc.isSelected);
    if (totalSelected.length === modifiedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments(uploadedDocuments);
  };
  const handleSelection = (idx) => {
    const modifiedData = uploadedDocuments[selectedDoc].documentUrls.map(
      (doc, index) => {
        if (idx === index) {
          return { ...doc, isSelected: !doc.isSelected };
        } else {
          return doc;
        }
      }
    );
    const totalSelected = modifiedData.filter((doc) => doc.isSelected);
    if (totalSelected.length === modifiedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments([...uploadedDocuments]);
  };
  const handleFolderSort = () => {
    let documentsClone = uploadedDocuments;
    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }

    setUploadedDocuments(documentsClone);
  };
  const handleImagesSort = () => {
    let documentsClone = uploadedDocuments[selectedDoc].documentUrls;

    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    let modifiedData = uploadedDocuments;
    modifiedData[selectedDoc].documentUrls = documentsClone;
    setUploadedDocuments([...modifiedData]);
  };
  const uploadImage = async (e) => {
    setUploadStatus(true);
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      const fileName = e.target.files[0].name.slice(0, -4);
      let res = await AuthServices.splitAndUploadImage(data, setPercentage);
      const modifiedData = res?.data?.urls?.map((url) => {
        return { documentUrl: url, isSelected: true, isDownloaded: false };
      });
      let cloneData = [...uploadedDocuments];
      cloneData.push({ documentName: fileName, documentUrls: modifiedData });

      setUploadedDocuments([...cloneData]);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert("Error occurred while uploading image");
      }
    }
    setPercentage(0);
    setUploadStatus(false);
  };
  //Method to upload the pdf doc
  async function uploadDocument(event) {
    setUploadStatus(true);
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        setDocumentName(event.target.files[0].name.slice(0, -4));
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([imageBlob]);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");
    try {
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 3 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            imagesList.push(blobUrl);
            if (i === pdfDocument.numPages) {
              setImages(imagesList);
            }
          }
        }, "image/png");
      }
    } catch (error) {
      console.error("Error getting image list:", error);
    } finally {
    }
  }
  //Method to upload the image list's blob data to s3 bucket
  const uploadImages = async () => {
    let data = new FormData();
    for (let index = 0; index < images.length; index++) {
      let image = images[index];
      if (!(image instanceof Blob)) {
        const response = await fetch(image);
        image = await response.blob();
      }
      data.append("images", image, `${documentName + "_" + index}.jpeg`);
    }
    try {
      let res = await AuthServices.uploadPdfToImage(data, setPercentage);
      console.log("Image data resonse from apis", res.data.urls);
      const modifiedData = res?.data?.urls?.map((url) => {
        return { documentUrl: url, isSelected: true, isDownloaded: false };
      });
      let cloneData = [...uploadedDocuments];
      cloneData.push({ documentName, documentUrls: modifiedData });

      setUploadedDocuments([...cloneData]);
      setPdfDocument(null);
      setImages([]);
      window.alert("Image uploaded successfully");
    } catch (error) {
      console.error("Upload failed:", error);
    }
    setPercentage(0);
    setUploadStatus(false);
  };
  const generateMergedPDF = async () => {
    setIsDownloading(true);
    let imagesUrl = [];
    for (let doc of uploadedDocuments) {
      for (let image of doc?.documentUrls) {
        if (image.isSelected) {
          imagesUrl.push({
            image: image.documentUrl,
            compressionPossible: true,
          });
        }
      }
    }

    try {
      let response = await AuthServices.loadImagesBase64(
        {
          images: imagesUrl,
        },
        setPercentage,
        setNetSpeed
      );
      downloadPdf(response.data, "merged-document");
    } catch (error) {
      window.alert(error);
    }
    setIsDownloading(false);
  };
  const generateSinglePdf = async (compression) => {
    setIsDownloading(true);
    let imagesUrl = [];
    for (let image of uploadedDocuments[selectedDoc]?.documentUrls) {
      if (image.isSelected) {
        imagesUrl.push({
          image: image.documentUrl,
          compressionPossible: compression,
        });
      }
    }

    try {
      let response = await AuthServices.loadImagesBase64(
        {
          images: imagesUrl,
        },
        setPercentage,
        setNetSpeed
      );
      console.log(
        "document name:",
        uploadedDocuments[selectedDoc]?.documentName
      );
      downloadPdf(response.data, uploadedDocuments[selectedDoc]?.documentName);
      const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
        (doc) => {
          if (doc.isSelected) {
            return {
              ...doc,
              isDownloaded: true,
            };
          }
          return doc;
        }
      );
      uploadedDocuments[selectedDoc].documentUrls = modifiedData;
      setUploadedDocuments(uploadedDocuments);
    } catch (error) {
      window.alert(error);
    }
    setIsDownloading(false);
  };

  //This use effect is to check or uncheck "Select All" checkbox when we select another document
  useEffect(() => {
    if (selectedDoc !== null) {
      const filteredData = uploadedDocuments[selectedDoc]?.documentUrls?.filter(
        (doc) => doc.isSelected
      );
      if (
        filteredData.length ===
        uploadedDocuments[selectedDoc]?.documentUrls?.length
      ) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [selectedDoc]);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);
  useEffect(() => {
    images.length > 0 && uploadImages();
  }, [images]);

  console.log("images:", images);
  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Documents
      </span>
      <div className="heading">Manage Documents</div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5 h-fit"
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-1 items-end text-xl text-[#191919] font-semibold">
            Uploaded Documents{" "}
            <div className="text-sm font-semibold">
              {uploadedDocuments.length}
            </div>
          </div>
          <div className="flex gap-2 ">
            {uploadStatus ? (
              <CircularProgress />
            ) : (
              <div
                className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                onClick={() => handleButtonClick()}
              >
                <FileUploadOutlinedIcon fontSize="small" /> Upload Document
              </div>
            )}
            {isDownloading ? (
              <CircularProgress />
            ) : (
              uploadedDocuments.length > 0 && (
                <div
                  className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                  onClick={generateMergedPDF}
                >
                  <img src={DownloadIcon} alt="download-icon " />
                  Merge and Download PDF
                </div>
              )
            )}
          </div>
          <input
            type="file"
            onChange={uploadDocument}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
        </div>
        {uploadStatus > 0 && (
          <div className="flex items-center gap-1">
            {" "}
            <div
              style={{ width: `${percentage}%` }}
              className="h-[15px] bg-blue-500 mt-2 rounded-full"
            />
            {percentage}%
          </div>
        )}
        {uploadedDocuments.length < 1 && (
          <div className="text-center text-xl font-semibold mt-8">
            {" "}
            Please Upload Documents
          </div>
        )}
        <div className="flex gap-4 flex-wrap my-8">
          {uploadedDocuments?.map((doc, idx) => {
            return (
              <div
                key={idx}
                style={{
                  border:
                    selectedDoc === idx
                      ? "1px solid #017C57"
                      : "1px solid #DEDEDE",
                }}
                className={`w-[200px] cursor-pointer rounded-[8px] py-2 px-4 ${
                  selectedDoc === idx ? "p-1 bg-[#f5f5f5] " : "p-1 bg-white"
                }`}
                onClick={() => setSelectedDoc(idx)}
                draggable
                onDragStart={() => (dragDocument.current = idx)}
                onDragEnter={() => (draggedOverDocument.current = idx)}
                onDragEnd={handleFolderSort}
              >
                <img
                  src={FolderIcon}
                  alt="folder-icon"
                  height={50}
                  width={50}
                />
                <div className="text-sm text-[#191919] font-medium my-1">
                  {doc.documentName}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ border: "1px solid #DEDEDE" }} className=" my-6" />
        {selectedDoc != null && (
          <div className="flex gap-2  justify-end py-4">
            <div className="flex items-center gap-1 text-sm ">
              <input
                type="checkbox"
                checked={selectAll}
                className="w-[18px] h-[18px]"
                onClick={handleSelectAll}
              />{" "}
              Select All
            </div>
            <div className="flex items-center gap-1 text-sm ">
              <input
                type="checkbox"
                checked={selectNonDownloaded}
                className="w-[18px] h-[18px]"
                onClick={handleSelectNonDownloaded}
              />{" "}
              Select Non-Downloaded
            </div>
            <div
              style={{ border: "1px solid #017C57" }}
              className=" w-fit flex item-center gap-2 text-xs text-[#017C57] font-semibold uppercase px-4 py-2 rounded-full cursor-pointer"
              onClick={() => {
                setOpenImagesGallery(true);
                setSelectedImage(0);
              }}
            >
              <img src={EyeIcon} alt="eye-icon" /> Gallery View
            </div>
          </div>
        )}
        {openImagesGallery && (
          <ImagesGallery
            setOpen={setOpenImagesGallery}
            data={uploadedDocuments[selectedDoc]?.documentUrls}
            selectedImage={selectedImage}
          />
        )}
        <div className="flex flex-wrap gap-3 mt-4">
          {selectedDoc !== null &&
            uploadedDocuments[selectedDoc]?.documentUrls?.map((image, idx) => {
              return (
                <div
                  key={idx}
                  style={{ border: "1px solid #D7D7D7" }}
                  className="relative h-[200px] w-[170px] flex items-center justify-center rounded-[8px] mx-1 cursor-pointer hovernow"
                  draggable
                  onDragStart={() => (dragDocument.current = idx)}
                  onDragEnter={() => (draggedOverDocument.current = idx)}
                  onDragEnd={handleImagesSort}
                >
                  <img
                    src={image.documentUrl}
                    alt="document-image"
                    height={180}
                    width={160}
                    onClick={() => {
                      setSelectedImage(idx);
                      setOpenImagesGallery(true);
                    }}
                  />
                  <div className="absolute top-1 right-1 w-[18px]">
                    <input
                      type="checkbox"
                      checked={image.isSelected}
                      onClick={() => handleSelection(idx)}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        {uploadedDocuments.length > 0 && (
          <div className="flex justify-center mt-8">
            {isDownloading ? (
              <CircularProgress />
            ) : (
              selectedDoc !== null && (
                <div className="flex gap-2">
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="flex items-center gap-2 text-[#017C57] bg-white rounded-full px-4 py-2 cursor-pointer"
                    onClick={() => generateSinglePdf(true)}
                  >
                    <img src={DownloadIconGreen} alt="download-icon " />{" "}
                    Download Compressed PDF
                  </div>
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="flex items-center gap-2 text-[#017C57] bg-white rounded-full px-4 py-2 cursor-pointer"
                    onClick={() => generateSinglePdf(false)}
                  >
                    <img src={DownloadIconGreen} alt="download-icon " />{" "}
                    Download Original PDF
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditDocuments;
