import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(CategoryScale, ChartDataLabels);

export const PieChart = ({ chartData }) => {
  return (
    <div style={{ width: "170px" }}>
      <Doughnut
        data={chartData}
        options={{
          cutout: "75%",
          plugins: {
            legend: {
              display: false,
              position: "left",
              align: "start",
            },
          },
        }}
      />
    </div>
  );
};

export const BarChart = ({ chartData }) => {
  return (
    <div
      style={{
        width: "70PX",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Bar
        data={chartData}
        options={{
          aspectRatio: 0.41,
          scales: {
            x: {
              grid: {
                display: false,
              },
              axisTitle: {
                display: true,
                text: "Team1",
                color: "#2B2B2B",
              },
              ticks: {
                display: false,
              },
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
      <div
        style={{ fontFamily: "inherit", fontSize: "8px", fontWeight: "600" }}
      >
        Team A
      </div>
    </div>
  );
};

export const ChartLabel = ({ label, color }) => {
  return (
    <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <div
        style={{
          width: "16px",
          height: "16px",
          backgroundColor: `${color}`,
          border: "none",
        }}
      ></div>
      <div
        style={{
          color: "#797979",
          fontFamily: "inherit",
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        {label}
      </div>
    </div>
  );
};
