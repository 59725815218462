import { createSlice } from "@reduxjs/toolkit";

export const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState: {
    caseId: "",
    pageNo: "",
    member: "",
    status: "",
    subStatus: "",
    selectedHospitalId: "",
  },
  reducers: {
    updateCaseId: (state, action) => {
      state.caseId = action.payload;
    },
    updatePageNumber: (state, action) => {
      state.pageNo = action.payload;
    },
    updateMember: (state, action) => {
      state.member = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.status;
    },
    updateSubStatus: (state, action) => {
      state.subStatus = action.payload;
    },
    updateSelectedHospital: (state, action) => {
      state.selectedHospitalId = action.payload;
    },
  },
});

export const {
  updateCaseId,
  updatePageNumber,
  updateMember,
  updateStatus,
  updateSubStatus,
  updateSelectedHospital,
} = caseDetailSlice.actions;

export default caseDetailSlice.reducer;
