import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CustomPagination from "../../../utils/paginationUtils";
import MapDocumentsNewPage from "./MapDocumentsNewPage";
import { logOut } from "../../../config/user";
import { CaseServices } from "../../../services/CaseServices";
import "./MapDocumentCaseList.scss";

const MapDocumentCaseList = ({ setList }) => {
  const [cases, setCases] = useState([1]);
  let [selectedCase, setSelectedCase] = useState({});
  const [mapDocuments, setMapDocuments] = useState(false);

  const handleMapDocments = (casedetail) => {
    setSelectedCase(casedetail);
    setMapDocuments(true);
  };
  async function mappingPendingCases() {
    try {
      let res = await CaseServices.mappingPendingCases();
      // setCases(res?.data?.caseList)
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  }

  useEffect(() => {
    mappingPendingCases();
  }, []);

  return (
    <div className="map-document-case-list-container">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Map Documents
      </span>
      {/* <div className="heading">Map Documents</div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "32px",
        }}
      /> */}
      {mapDocuments ? (
        <MapDocumentsNewPage />
      ) : (
        <Paper className="list-paper">
          <div className="heading">
            Total Cases
            <input
              type="text"
              className="input-search"
              placeholder="Search anything"
            />
          </div>
          <div className="sub-heading">
            Sort by
            <select className="outlined-btn" style={{ width: "194px" }}>
              <option>TEAM - MANAGER</option>
            </select>
            <select className="outlined-btn" style={{ width: "162px" }}>
              <option>CASE - STATUS</option>
            </select>
            <select className="outlined-btn" style={{ width: "143px" }}>
              <option>HOSPITALS</option>
            </select>
          </div>
          <hr className="line" />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Benfeciary no.
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Patient name
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  HUID
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Hospital Name
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  City
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Gender
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Age
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Registered Date
                </TableCell>
                <TableCell
                  className="table-head"
                  align="center"
                  sx={{ borderBottom: 0 }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cases.map((cas, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {/* <TableRow
                    style={
                      idx % 2 !== 0
                        ? { backgroundColor: "#F9F9F9", borderRedius: "8px" }
                        : {}
                    }
                  >
                    <TableCell colSpan="9">
                      <div className="cell-container">
                        <div className="left-container">
                          <button className="filled-btn">ACTIVE</button>{" "}
                          <div>Team B</div>
                          <FiberManualRecordRoundedIcon className="dot" />
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Manager Name
                          </div>
                        </div>
                        <div className="right-container">
                          <div>Time Remaining</div>
                          <div>-</div>
                          <div style={{ fontSize: "16px", fontWeight: "600" }}>
                            00:00:00:00
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow> */}
                    <TableRow
                      className="table-body"
                      style={
                        idx % 2 !== 0
                          ? { backgroundColor: "#F9F9F9", borderRadius: "8px" }
                          : {}
                      }
                    >
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.beneficiaryNo}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.patient?.patientName}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas.huid}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.hospitalName}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.patient?.city}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.patient?.gender}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.patient?.age}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        {cas?.registeredDate}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} align="center">
                        <button
                          onClick={() => handleMapDocments(cas)}
                          className="text-btn"
                        >
                          MAP DOCUMENT
                        </button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {!mapDocuments ? (
        <div className="list-pagination">
          <CustomPagination />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MapDocumentCaseList;
