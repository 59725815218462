import React, { useState } from "react";
import { Paper } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { PieChart } from "../utils/chartUtils";
import { ChartLabel } from "../utils/chartUtils";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./hospitalWiseStatus.scss";

const HospitalWiseStatus = () => {
  const [hospitalCaseList, setHospitalCaseList] = useState([1, 2, 3, 4, 5]);
  return (
    <Paper className="paper1">
      <div className="heading">
        Hospital Wise Status
        <select className="outlined-btn">
          <option value=""> THIS MONTH</option>
        </select>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />
      <div className="chart-container">
        <div>
          <ChartLabel label="Total Cases" color="#CDFAF4" />
          <ChartLabel label="Total Pending Cases" color="#FFF0B4" />
        </div>
        <PieChart
          chartData={{
            labels: ["Total Cases", "Total Pending cases"],
            datasets: [
              {
                label: "Users Gained ",
                data: [10, 20],
                backgroundColor: ["#cdfaf4", "#FFF0B4"],
                borderColor: "white",
                borderWidth: 1,
                borderRadius: 80,
              },
            ],
          }}
        />
      </div>
      <div className="heading" style={{ fontSize: "16px" }}>
        Total Cases
        <button className="outlined-btn" style={{ width: "119px" }}>
          EXPAND <OpenInNewOutlinedIcon fontSize="small" />
        </button>
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          margin: "0px 16px 24px 16px",
        }}
      />
      {hospitalCaseList.map((cases, idx) => {
        return (
          <div key={idx} className="case-list">
            <div className="left-column">
              <div style={{ fontWeight: 600 }}>Apollo</div>
              <FiberManualRecordRoundedIcon className="dot" />
              <div>Total Cases - 9999</div>
            </div>
            <div className="right-column">
              <button className="tab-button">ACTIVE</button>
              <button
                className="tab-button"
                style={{ color: "#BCA03C", backgroundColor: "#FFF3C6" }}
              >
                999 PENDING CASES
              </button>
              <KeyboardArrowDownRoundedIcon className="drop-down" />
            </div>
          </div>
        );
      })}
    </Paper>
  );
};

export default HospitalWiseStatus;
