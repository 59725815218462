import axios from "axios";
import {
  getAuthorizationToken,
  getSignUpAuthorizationToken,
} from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

// function signup(details) {
//   return axios({
//     method:"post",
//     url: `${REACT_APP_API_URL}/users/signup`,
//     data: details,
//   });
// }

function login(details) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/login`,
    data: details,
  });
}

// function emailOtp(email){
//   return axios({
//     method:"get",
//     url: `${REACT_APP_API_URL}/users/emailotp/${email}`,
//     headers:getSignUpAuthorizationToken()
//   });
// }

// function phoneOtp(mobileNumber){
//   return axios({
//     method:"get",
//     url: `${REACT_APP_API_URL}/users/phoneotp/${mobileNumber}`,
//     headers:getSignUpAuthorizationToken()
//   });
// }

// function getUsers(){
//   return axios({
//     method:"get",
//     url: `${REACT_APP_API_URL}/users/all`,
//     headers:getAuthorizationToken()
//   });
// }

function uploadImage(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/upload`,
    data: data,
    // headers:getAuthorizationToken('form-data')
  });
}
function splitAndUploadImage(data, setPercentage) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/split-and-upload`,
    data: data,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      console.log("percent:", percent);
      console.log(`${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console

      if (percent <= 100) {
        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
    // headers:getAuthorizationToken('form-data')
  });
}

function uploadPdfToImage(data, setPercentage) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/upload-images`,
    data: data,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / total);
      console.log("percent:", percent);
      console.log(`${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console

      if (percent <= 100) {
        setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
      }
    },
    // headers:getAuthorizationToken('form-data')
  });
}

export const phoneOtpService = async (phoneNumber) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/sign-up-phone-otp`,
      {
        countryCode: "+91",
        phone: phoneNumber,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
export const resendPhoneOtpService = async (phoneNumber) => {
  return await axios.get(`${REACT_APP_API_URL}/phone-otp/${phoneNumber}`, {
    headers: getSignUpAuthorizationToken(),
  });
};
export const phoneotpverify = async (otp) => {
  return await axios.post(
    `${REACT_APP_API_URL}/sign-up-phone-verify`,
    { otp: otp },
    { headers: getSignUpAuthorizationToken() }
  );
};
export const forgotEmailOtpVerification = async (email) => {
  return await axios.get(`${REACT_APP_API_URL}/email-otp/${email}`);
};
export const forgotOtpVerification = async (email_otp) => {
  return await axios.get(`${REACT_APP_API_URL}/email-otp/${email_otp}`);
};

export const emailotpservice = async (email) => {
  return await axios.post(
    `${REACT_APP_API_URL}/sign-up-email-otp/`,
    { email: email },
    { headers: getSignUpAuthorizationToken() }
  );
};

export const emailotpverify = async (otp) => {
  return await axios.post(
    `${REACT_APP_API_URL}/sign-up-email-verify/`,
    { otp: otp },
    { headers: getSignUpAuthorizationToken() }
  );
};

export const Passwordverify = async (password) => {
  return await axios.post(
    `${REACT_APP_API_URL}/sign-up-complete/`,
    { password: password },
    { headers: getSignUpAuthorizationToken() }
  );
};

export const handlelogin = async (email, password) => {
  return await axios.post(
    `${REACT_APP_API_URL}/login/`,
    { email: email, password: password },
    { headers: getAuthorizationToken() }
  );
};

export const handl = async (email, password) => {
  return await axios.post(
    `${REACT_APP_API_URL}/login/`,
    { email: email, password: password },
    { headers: getAuthorizationToken() }
  );
};

function createHospital(values) {
  return axios.post(
    `${REACT_APP_API_URL}/sign-up-add-hospital`,
    {
      name: values.hospitalName,
      country: values.country,
      state: values.state,
      city: values.city,
      pmjayUserId: values.pjmyid,
      pincode: values.pincode,
      fullname: values.fullName,
      position:
        values.position === "Other" ? values.otherPosition : values.position,
      businessDocumentType: values.businessDocumentType,
      businessDocumentUrl: values.businessDocumentUrl,
      businessDocumentNumber: values.businessDocumentNumber,
      pmjayPassword: values.pjmypassword,
    },
    {
      headers: getSignUpAuthorizationToken(),
    }
  );
}
function changePassword(email, sentOtp, password, serviceUsed) {
  return axios.put(`${REACT_APP_API_URL}/reset-password`, {
    email: email,
    emailOtp: sentOtp,
    password: password,
    serviceUsed: serviceUsed,
  });
}
function updateFirebaseId(data) {
  return axios.patch(`${REACT_APP_API_URL}/update-firebaseId`, data, {
    headers: getAuthorizationToken(),
  });
}

function loadImagesBase64(data, setPercentage, setNetSpeed) {
  return axios.post(`${REACT_APP_API_URL}/load-image`, data, {
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      console.log("progressEvent", progressEvent);
      console.log("speed:", progressEvent?.rate / 1024, "KB/s");
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setPercentage(percentCompleted);
      setNetSpeed(progressEvent?.rate);
    },
  });
}
export const AuthServices = {
  login,
  // signup,
  // emailOtp,
  // phoneOtp,
  // getUsers,
  changePassword,
  uploadImage,
  splitAndUploadImage,
  uploadPdfToImage,
  createHospital,
  updateFirebaseId,
  loadImagesBase64,
};
