import React, { useState, useEffect, useRef } from "react";
import { logOut } from "../config/user";
import { CircularProgress } from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { HospitalServices, hospitalupdate } from "../services/HospitalServices";
import "./ManageRpa.scss";
import { Switch, Stack } from "@mui/material";
import editIcon from "../assets/editIcon.svg";
import crossIcon from "../assets/crossIcon.svg";
import CustomPagination from "../utils/paginationUtils";
import EyeIcon from "../assets/eyeIcon.svg";
const ManagedRpa = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const searchTimeRef = useRef(null);
  const limit = 10;
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePasswordVisibility = (hospitalId) => {
    setIsPasswordVisible((prevId) =>
      prevId === hospitalId ? null : hospitalId
    );
    setIsPasswordVisible((prevId) =>
      prevId === hospitalId ? null : hospitalId
    );
  };
  const handleSearch = (e) => {
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const fetchHospitalList = async () => {
    setIsLoading(true);
    try {
      const res = await HospitalServices.getHospitalUserList(
        searchKey,
        page,
        limit
      );
      if (res?.data?.userList) {
        setHospitalList(res.data.userList);
        setTotalPages(res.data.totalPages || 1);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        alert(error?.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    if (
      !selectedUser?.fullName ||
      !selectedUser?.email ||
      !selectedUser?.phone
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    const data = {
      fullName: selectedUser?.fullName,
      email: selectedUser?.email,
      phone: selectedUser?.phone,
      hospital: selectedUser?.hospital?.name,
      pmjayPassword: selectedUser?.pmjayPassword,
      pmjayUserId: selectedUser?.pmjayUserId,
      tmsVersion: selectedUser?.tmsVersion,
      applicationId: selectedUser?.applicationId,
    };

    try {
      setIsLoading(true);
      const response = await hospitalupdate(selectedUser?.hospital?.id, data);
      if (response?.status === 200) {
        alert("Changes saved successfully!");
        setIsPopupVisible(false);
        fetchHospitalList();
      } else {
        alert("Failed to update hospital details.");
      }
    } catch (error) {
      alert("Error updating hospital details: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (hospital, status) => {
    const updatedStatus = hospital?.pmjaystatus === true ? false : true;

    const updatedData = {
      userid: hospital.id,
      pmjaystatus: updatedStatus,
    };
    try {
      const response = await HospitalServices.updateUserRpaDetails(updatedData);

      if (response?.status === 200) {
        fetchHospitalList();
      }
    } catch (error) {
      alert("Error while changing status");
    }
  };

  useEffect(() => {
    fetchHospitalList();
  }, [page, searchKey]);

  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Dashboard &nbsp;/ &nbsp; Manage Hospital
      </span>

      <div className="flex justify-between items-center">
        <div className="heading">Manage Hospital</div>
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 px-2 py-2 rounded-[8px] "
        >
          <SearchIcon />
          <input
            type="text"
            className="outline-none border-0"
            placeholder="Search Anything"
            onChange={handleSearch}
          />
        </div>
      </div>
      <hr style={{ color: "#E7E7E7", marginBottom: "20px" }} />

      <div className="Second-container">
        <div className="flex justify-between">
          {!isLoading ? (
            hospitalList?.length > 0 ? (
              <section className="mt-1">
                <div className="grid grid-cols-7 text-[#191919] font-semibold px-8 py-1 gap-2">
                  <div className="text-[18px] text-[#191919] font-semibold  ">
                    Hospital Name
                  </div>
                  <div className="text-[18px]">Hospital Admin</div>
                  <div className="text-[18px]">Phone Number</div>
                  <div className="text-[18px] ">Email</div>
                  <div className="text-[18px]">City</div>
                  <div className="text-[18px]">Pmjay Password</div>
                  <div className="text-[18px] text-center">Action</div>
                </div>

                {hospitalList.map((hospital, idx) => (
                  <article
                    key={idx}
                    className={`py-6 px-8 rounded-[16px] ${
                      idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                    }`}
                  >
                    <div
                      className={`grid grid-cols-7 text-[#797979] text-[18px] gap-2`}
                    >
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.hospital?.name || "--"}
                      </div>
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.fullName || "--"}
                      </div>
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.phone || "--"}
                      </div>
                      <div
                        className={` text-[#797979] text-[18px] break-words`}
                      >
                        {hospital?.email || "--"}
                      </div>
                      <div className={` text-[#797979] text-[15px] `}>
                        {hospital?.hospital?.city || "--"}
                      </div>
                      <div className="text-[14px]">
                        <div
                          onClick={() => togglePasswordVisibility(hospital.id)}
                        >
                          {isPasswordVisible === hospital.id
                            ? hospital?.hospital?.pmjayPassword || "--"
                            : "****"}
                          <img src={EyeIcon} alt="eye-icon" />
                        </div>
                      </div>

                      <div className="flex justify-center items-start">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span style={{ textTransform: "capitalize" }}>
                            {hospital?.pmjaystatus ? "Active" : "Inactive"}
                          </span>
                          <Switch
                            checked={hospital?.pmjaystatus === true}
                            onChange={() =>
                              handleStatusChange(
                                hospital,
                                hospital?.pmjaystatus
                              )
                            }
                            inputProps={{ "aria-label": "toggle status" }}
                            style={{
                              color: hospital?.pmjaystatus
                                ? "#017C57"
                                : "#B0B0B0",
                            }}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                backgroundColor: hospital?.pmjaystatus
                                  ? "#017C57"
                                  : "#D3D3D3",
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: hospital?.pmjaystatus
                                  ? "#017C57"
                                  : "#D3D3D3",
                              },
                            }}
                          />

                          <span>
                            <img
                              src={editIcon}
                              alt="edit-icon"
                              className="w-[18px] cursor-pointer"
                              onClick={() => {
                                setSelectedUser(hospital); // Set the selected hospital
                                setIsPopupVisible(true); // Show the popup
                              }}
                            />
                          </span>
                        </Stack>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
            ) : (
              <div className="w-full h-full flex justify-center items-center font-semibold text-xl text-[#191919]">
                No Data Found
              </div>
            )
          ) : (
            <div className="w-full h-[500px] flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative h-[90%] w-[328px] xl:w-[50%] bg-white rounded-[16px] p-4 xl:p-8">
            <div className="absolute right-12 top-8 cursor-pointer">
              <img
                src={crossIcon}
                alt="edit-icon"
                className="w-[30px] cursor-pointer"
                onClick={() => {
                  setIsPopupVisible(false);
                }}
              />
            </div>

            <div className="space-y-4">
              <div className="flex gap-[16px] pt-6 pl-10 gap-2">
                <div className="w-[776px] h-[428px] p-[16px] border border-[rgba(228,228,228,1)] rounded-[8px] flex flex-col gap-[16px]">
                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label htmlFor="fullName" className="font-semibold block">
                        UserName:
                      </label>
                      <input
                        id="username"
                        type="text"
                        value={selectedUser?.fullName || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            username: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]  "
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="email" className="font-semibold block">
                        Email:
                      </label>
                      <input
                        id="email"
                        type="text"
                        value={selectedUser?.email || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            email: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3  "
                      />
                    </div>
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label htmlFor="phone" className="font-semibold block">
                        Phone:
                      </label>
                      <input
                        id="phone"
                        type="text"
                        value={selectedUser?.phone || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            phone: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]  "
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="hospital" className="font-semibold block">
                        Hospital Name:
                      </label>
                      <input
                        id="hospital"
                        type="text"
                        value={selectedUser?.hospital.name || ""}
                        disabled
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]  "
                      />
                    </div>
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label
                        htmlFor="tmsUserId"
                        className="font-semibold block"
                      >
                        TMS User Id:
                      </label>
                      <input
                        id="tmsUserId"
                        type="text"
                        value={selectedUser?.hospital?.pmjayUserId || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            pmjayUserId: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] "
                      />
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="tmsPassword"
                        className="font-semibold block"
                      >
                        TMS Password:
                      </label>

                      <div className="relative mt-[15px]">
                        <input
                          id="tmsPassword"
                          type={isPasswordVisible ? "text" : "password"} // Toggle between 'text' and 'password'
                          value={selectedUser?.hospital?.pmjayPassword || ""}
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              pmjayPassword: e.target.value,
                            })
                          }
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]"
                        />
                        <img
                          src={EyeIcon}
                          alt="eye-icon"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          onClick={togglePasswordVisibility} // Add click handler to toggle visibility
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label
                        htmlFor="tmsVersion"
                        className="font-semibold block"
                      >
                        TMS Version:
                      </label>
                      <select
                        id="tmsVersion"
                        value={selectedUser?.hospital?.tmsVersion || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            hospital: {
                              ...selectedUser.hospital,
                              tmsVersion: e.target.value,
                            },
                          })
                        }
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] border border-[#E4E4E4] mt-[15px]"
                      >
                        <option value="">Select Version</option>
                        <option value="1.0">1.0</option>
                        <option value="2.0">2.0</option>
                      </select>
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="applicationId"
                        className="font-semibold block"
                      >
                        Application Id:
                      </label>
                      <select
                        id="applicationId"
                        value={selectedUser?.hospital?.applicationId || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            hospital: {
                              ...selectedUser.hospital,
                              applicationId: e.target.value,
                            },
                          })
                        }
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] border border-[#E4E4E4] mt-[15px]"
                      >
                        <option value="">Select Application Id</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                  </div>

                  <div class="flex justify-center mt-8">
                    <div className="text-white text-xs font-semibold bg-[#017C57] rounded-full py-1 px-2 cursor-pointer">
                      <button
                        onClick={handleSaveChanges}
                        className="save-button"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default ManagedRpa;
