import axios from "axios";
import { getAuthorizationToken } from "../config/user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
 
 
function addPatientDetails(data){
  return axios({
    method:"post",
    url: `${REACT_APP_API_URL}/addpatient`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function getPatientDetails(patientId){
  return axios({
    method:"get",
    url: `${REACT_APP_API_URL}/patient-details/${patientId}`,
    headers:getAuthorizationToken()
  });
}

function updatePatient(data, patientId){
  return axios({
    method:"put",
    url: `${REACT_APP_API_URL}/update-patient/${patientId}`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function addFamilyHistory(data){
  return axios({
    method:"post",
    url: `${REACT_APP_API_URL}/add-family-history`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function updateFamilyHistory(data, historyId){
  return axios({
    method:"put",
    url: `${REACT_APP_API_URL}/update-family-history/${historyId}`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function addPersonalHistory(data){
  return axios({
    method:"post",
    url: `${REACT_APP_API_URL}/add-personal-history`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function addPastIllness(data){
  return axios({
    method:"post",
    url: `${REACT_APP_API_URL}/add-past-illness`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function updatePastIllness(data, illnessId){
  return axios({
    method:"put",
    url: `${REACT_APP_API_URL}/update-past-illness/${illnessId}`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function addPresentIllness(data){
  return axios({
    method:"post",
    url: `${REACT_APP_API_URL}/add-peresent-illness`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function updatePresentIllness(data, illnessId){
  return axios({
    method:"put",
    url: `${REACT_APP_API_URL}/update-present-illness/${illnessId}`,
    data:data,
    headers:getAuthorizationToken()
  });
}

function updatePersonalHistory(data, historyId){
  return axios({
    method:"put",
    url: `${REACT_APP_API_URL}/update-personal-history/${historyId}`,
    data:data,
    headers:getAuthorizationToken()
  });
}

export const PatientServices = {
  addPatientDetails,
  getPatientDetails,
  addFamilyHistory,
  updateFamilyHistory,
  addPersonalHistory,
  updatePersonalHistory,
  addPastIllness,
  updatePastIllness,
  addPresentIllness,
  updatePresentIllness,
  updatePatient,
};
