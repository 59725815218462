import React from 'react';
// import { Bar } from 'react-chartjs-2';
import './lengthbar.scss';

const Lenghtbar = () => {
  return (
    <div className='box-container'>
      <select name="dropdown" className='metric'>
        <option value="option1">Metric</option>
      </select>
      <select name="dropdown" className='Today'>
        <option value="option1">Today</option>
      </select>
    </div>
  );
};

// const options = {
//   responsive: true,
//   plugins: {
//     legend: { position: "chartArea" },
//     title: {
//       display: true,
     
//     },
//   },
// };

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//   datasets: [
//     {
//       label: "Product A",
//       data: [20, 30, 40, 50, 60, 70],
//       backgroundColor: "green",
//     },
//     {
//       label: 'Product B',
//       data: [15, 20, 25, 40, 45, 60],
//       backgroundColor: 'blue',
//     },
//   ],
// };

export default function App() {
  return (
    <div className="App">
      <Lenghtbar />
      {/* <Bar options={options} data={data} /> */}
    </div>
  );
}
