import React from 'react';
import './Undersettled.scss'
function App() {
  return (
    <div className='Container'>
        <h1 style={{fontFamily:"Inter",fontWeight:"600",fontSize:"20px",lineHeight:"24.2px"}}>Undersettled Revenue loss</h1>
        <hr style={{width:"448px",border:"1px solid rgba(228, 228, 228, 1)"}}/>
        <div style={{width:"448px",height:"162px"}}></div>
        <div style={{ display:"flex",width:"448px",height:"52px",padding:"8px, 0px, 8px, 0px",gap:"64px"}}>
        <text >Percentage before <br />
            settlemed
        </text>
        <text >Percentage before <br />
            settlemed
        </text>
        </div>
    </div>

  );
}

export default App;

