import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CaseListV2 from "./caseListV2";
import { useDispatch } from "react-redux";
import { setOutsideUser } from "../../redux/roleSlice";
import { useNavigate } from "react-router-dom";
import { CaseServices } from "../../services/CaseServices";
import "./manageCases.scss";

const ActiveCasesDashboardV2 = () => {
  const [caseStatus, setCaseStatus] = useState("");
  const [stageColor, setStageColor] = useState(null);
  const [isAddingCase, setIsAddingCase] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [caseStageReport, setCaseStageReport] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const caseStageData = [
    {
      caseStatus: "Request to Admission",
      pending: "2h",
      color: "#CF3636",
      bgColor: "#FFD6DB",
    },
    {
      caseStatus: "Request Admission Initiated",
      pending: "45m",
      color: "#F76E0B",
      bgColor: "#FFEAC6",
    },
    {
      caseStatus: "Admission Documents Uploaded",
      pending: "45m",
      color: "#F76E0B",
      bgColor: "#FFEAC6",
    },
    {
      caseStatus: "Admission Approved by team B",
      pending: "15m",
      color: "#1B86EB",
      bgColor: "#DAE3F8",
    },
    {
      caseStatus: "Admission Approved by team B",
      pending: "15m",
      color: "#1B86EB",
      bgColor: "#DAE3F8",
    },
    {
      caseStatus: "⁠Admission Approved on TMS",
      pending: "10m",
      color: "#017C57",
      bgColor: "#CDFAF4",
    },
    {
      caseStatus: "⁠Request Enhancement Initiated",
      pending: "2h",
      color: "#CF3636",
      bgColor: "#FFD6DB",
    },
    {
      caseStatus: "Enhancement Documents Uploaded",
      pending: "2h",
      color: "#CF3636",
      bgColor: "#FFD6DB",
    },
    {
      caseStatus: "Enhancement Approved to Team D",
      pending: "45m",
      color: "#F76E0B",
      bgColor: "#FFEAC6",
    },
    {
      caseStatus: "Enhancement Approved on TMS",
      pending: "10m",
      color: "#017C57",
      bgColor: "#CDFAF4",
    },
    {
      caseStatus: "Request Discharge Initiated",
      pending: "15m",
      color: "#1B86EB",
      bgColor: "#DAE3F8",
    },
    {
      caseStatus: "Claim Documents uploaded",
      pending: "10m",
      color: "#017C57",
      bgColor: "#CDFAF4",
    },
    {
      caseStatus: "Claim Approved by Team E",
      pending: "2h",
      color: "#CF3636",
      bgColor: "#FFD6DB",
    },
    {
      caseStatus: "Claim approved and document upload pending",
      pending: "2h",
      color: "#CF3636",
      bgColor: "#FFD6DB",
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("caseId");
  const token = searchParams.get("token");
  if (token) {
    dispatch(setOutsideUser(true));
    const userDetails = JSON.stringify({
      session: {
        token: atob(decodeURIComponent(token)),
        updatedAt: "2024-09-12T11:40:46.539Z",
        createdAt: "2024-09-12T11:40:46.539Z",
      },
      hospital: {},
    });
    localStorage.setItem("userDetails", userDetails);
  }

  const getPendingTime = (caseDate) => {
    let date1 = new Date(caseDate); // oldest case's date in this stage
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;

    // Calculate the difference in days, hours, minutes, and seconds
    let days = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
    let hours = Math.floor(
      (differenceInMilliseconds % (1000 * 3600 * 24)) / (1000 * 3600)
    );
    let minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 3600)) / (1000 * 60)
    );
    let seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };
  const getStageColor = (stageStatus, oldestCaseTime) => {
    let date1 = oldestCaseTime ? new Date(oldestCaseTime) : new Date(); // oldest case's date in this stage
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;
    let minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 3600)) / (1000 * 60)
    );
    const filteredData = stageStatus.filter(
      (stage) =>
        minutes >= Number(stage.startTime) && minutes <= Number(stage.endTime)
    );
    for (let stageColor of stageStatus) {
    }
    return filteredData[0]
      ? filteredData[0].bgColor
      : stageStatus[stageStatus.length - 1].bgColor;
  };
  const getStageCaseReport = async () => {
    try {
      const response = await CaseServices.stageCaseReport();
      console.log("case stage count response:", response.data);
      setCaseStageReport(response?.data?.customReport);
      setStageColor(response?.data?.stageStatusTimeColor);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleAddNewCase = () => {
    navigate("/addcases");
  };

  const handleSaveCase = (newCase) => {
    // Logic to save the new case

    setIsAddingCase(false);
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    getStageCaseReport();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Cases
      </span>

      {/* <div className="grid grid-cols-7 gap-3">
        {caseStageData?.map((stage, idx) => {
          return (
            <div
              key={idx}
              style={{ backgroundColor: stage.bgColor }}
              className="flex flex-col justify-between h-[68px] py-2 px-[5] rounded-[8px] cursor-pointer hovernow"
            >
              <div className="text-xs text-[#191919] text-center">
                {stage.caseStatus}
              </div>
              <div className="bg-white text-xs text-[#191919] font-semibold text-center py-[2px]">
                Pending From:{" "}
                <span style={{ color: stage.color }}>{stage.pending}</span>
              </div>{" "}
            </div>
          );
        })}
      </div> */}
      <div className="grid xl:grid-cols-5 2xl:grid-cols-7 gap-3">
        {caseStageReport?.map((stage, idx) => {
          return (
            <div
              key={idx}
              style={{
                backgroundColor: getStageColor(
                  stageColor[stage.stageStatusId],
                  stage.oldestCaseDate
                ),
              }}
              className="flex flex-col justify-between h-[90px] py-2 px-[5] rounded-[8px] cursor-pointer hovernow"
            >
              <div className="text-xs text-[#191919] text-center">
                {stage.mainStage}
              </div>
              <div className="text-sm text-center font-semibold">
                {stage.totalCaseCount}
              </div>
              <div className="bg-white text-xs text-[#191919] font-semibold text-center py-[2px]">
                Pending From:{" "}
                <span style={{ color: stage.color }}>
                  {stage?.oldestCaseDate
                    ? getPendingTime(stage.oldestCaseDate)
                    : "---"}
                </span>
              </div>{" "}
            </div>
          );
        })}
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBlock: "32px",
        }}
      />
      <div
        style={{
          display: width > 640 ? "flex" : "block",
          marginTop: "16px",
        }}
        className="heading"
      >
        Manage Cases
        <div className="btn-container">
          <button
            className="outlined-btn"
            style={caseStatus === "ACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("ACTIVE")}
          >
            ACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("INACTIVE")}
          >
            INACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            // onClick={() => setCaseStatus("INACTIVE")}
          >
            DELETE
          </button>
          <button
            style={{ cursor: "pointer" }}
            className="filled-btn"
            onClick={handleAddNewCase}
          >
            + ADD CASE
          </button>
        </div>
      </div>

      {isAddingCase ? (
        <div className="add-case-form">
          {/* Replace this with your form implementation */}
          <h3>Add New Case</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const newCase = {}; // Populate with form data
              handleSaveCase(newCase);
            }}
          >
            {/* Add form fields here */}
            <button type="submit">Save Case</button>
            <button type="button" onClick={() => setIsAddingCase(false)}>
              Cancel
            </button>
          </form>
        </div>
      ) : (
        <>
          {id ? (
            <CaseListV2
              caseStatus={caseStatus}
              setCaseStatus={setCaseStatus}
              caseId={atob(decodeURIComponent(id))}
            />
          ) : (
            <CaseListV2 caseStatus={caseStatus} setCaseStatus={setCaseStatus} />
          )}
        </>
      )}
    </div>
  );
};

export default ActiveCasesDashboardV2;
