import React, { useState } from "react";
import CustomPagination from "../../../../utils/paginationUtils";
import WifiCallingOutlinedIcon from '@mui/icons-material/WifiCallingOutlined';
import "./CallHistory.scss";
import { Paper } from "@mui/material";

const CallHistory = () => {
  let [callList, setCallList] = useState([1, 2, 3, 4]);
  return (
    <Paper className="call-history-container">
      <div className="heading">Call History</div>
      <hr className="line" />
      <table className="table">
        <thead>
          <tr className="table-head">
            <td></td>
            <td>Beneficiary no.</td>
            <td>Patient Name</td>
            <td>HUID</td>
            <td>Call type</td>
            <td>Duration</td>
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {callList.map((list, idx) => {
            return (
              <tr key={idx} className="table-body" style={idx%2!=0?{backgroundColor:'#F9F9F9'}:{}}>
                <td className="first-cell">
                  <button className="filled-btn">ACTIVE</button>
                </td>
                <td>SLS5R32464</td>
                <td>Sainad David</td>
                <td>2354</td>
                <td>
                  <button className="filled-btn" style={{width:'108px'}}> <WifiCallingOutlinedIcon sx={{height:'12px'}}/> INCOMING</button>
                </td>
                <td>30m</td>
                <td>10/12/2023</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="list-pagination">
        <CustomPagination />
      </div>
    </Paper>
  );
};

export default CallHistory;
