import React from "react";
import Countdown from "react-countdown";

const CountdownTimer = ({ deadline, color = "#797979" }) => {
  if (new Date(deadline) == "Invalid Date") {
    return;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span style={{ color: "#CF3636" }}>Time's up!</span>;
    } else {
      return (
        <span style={{ color: color }}>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  return <Countdown date={new Date(deadline)} renderer={renderer} />;
};

export default CountdownTimer;
